import * as React from "react"

const Remove = ({ ...props }) => {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7Z" fill="#F04533" />
      <path d="m4.308 4.309 5.384 5.384M9.692 4.309 4.308 9.693" stroke="#fff" />
    </svg>
  );
};



export default Remove
