import React from "react";
import { HolderOutlined } from "@ant-design/icons";
import ReactModal from "react-modal";
import { Plus, Remove, Setting } from "../../../components/svgIcons";
import ActionMore from "../../../components/common/ActionMore/component";

export default function QuizComponent(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const [ScreenModalIsOpen, setIsScreenOpen] = React.useState(false);
  function openScreenModal() {
    setIsScreenOpen(true);
  }
  function closeScreenModal() {
    setIsScreenOpen(false);
  }

  return (
    <>
      <div className="page__header">
        <div className="page__header--container">
          <div className="page__header--row align-items-center">
            <div className="page__header--left">
              <label className="custom__checkbox">
                <input
                  name="ShowQuizInApp"
                  onChange={(e) => props?.updateEventSettingData(e)}
                  checked={props?.eventSettingData?.ShowQuizInApp}
                  type="checkbox"
                  className="custom__checkbox--input"
                />
                <span className="custom__checkbox--check"></span>
                Show section tab in Mobile App{" "}
              </label>
            </div>
            <div className="page__header--right">
              <button
                type="button"
                className="btn btn__default"
                onClick={openScreenModal}
              >
                <Setting className="btn__icon" /> Quiz Cover Screen
              </button>
              <button
                type="button"
                className="btn btn__purple"
                onClick={openModal}
              >
                <Plus className="btn__icon" /> Add Quiz Question
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="custom__container">
        <div className="table-responsive table__responsive--custom">
          <table className="table table__custom items-order-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Question</th>
                <th>Answer Detail PDF</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="underline">
                  <HolderOutlined className="reorderIcon" />
                  Media
                </td>
                <td className="table-description">
                  In a survey of respondents of all ages, when asked for their
                  favorite entertainment activity, Gen Z were the only age-groud
                  not to list...
                </td>
                <td>
                  <button className="btn__text">Answer PDF</button>
                </td>
                <td className="text-end">
                  <ActionMore />
                </td>
              </tr>
              <tr>
                <td className="underline">
                  <HolderOutlined className="reorderIcon" />
                  Public Markets
                </td>
                <td className="table-description">
                  The following companies experienced post pandemic share price
                  rallies, but performance post rally has varied. Which of these
                  comp...
                </td>
                <td>
                  <button className="btn__text">Answer PDF</button>
                </td>
                <td className="text-end">
                  <ActionMore />
                </td>
              </tr>
              <tr>
                <td className="underline">
                  <HolderOutlined className="reorderIcon" />
                  Technology
                </td>
                <td className="table-description">
                  In a survey of respondents of all ages, when asked for their
                  favorite entertainment activity, Gen Z were the only age-groud
                  not to list ...
                </td>
                <td>
                  <button className="btn__text">Answer PDF</button>
                </td>
                <td className="text-end">
                  <ActionMore />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <div className="table__blank">
          <div className="table__blank--text">No users</div>
        </div> */}

        <div className="table__pagination">
          <div className="table__pagination--results">3 results</div>
          <div className="table__pagination--nav">
            <button
              type="button"
              className="btn btn__default table__pagination--prev disabled"
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn__default table__pagination--next disabled"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={ScreenModalIsOpen}
        onRequestClose={closeScreenModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Quiz Question</h5>
            {/* <button className="btn react-modal-close" onClick={closeModal}><Close /></button> */}
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">Question Type</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="label-primary">Question</label>
              <textarea type="text" className="form-control" />
            </div>
            <div className="answers-option-container">
              <div className="question-options">
                <span className="question-small-heading">Correct Answer</span>
                <div className="form-group">
                  <label className="label-primary">Answer Option 1</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form--inline-radio">
                  <label className="custom__radio lg">
                    <input className="custom__radio--input" type="radio" />
                    <span className="custom__radio--check lg"></span>
                  </label>
                </div>
              </div>
              <div className="question-options">
                <div className="form-group">
                  <label className="label-primary">Answer Option 2</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form--inline-radio">
                  <label className="custom__radio lg">
                    <input className="custom__radio--input" type="radio" />
                    <span className="custom__radio--check lg"></span>
                  </label>
                </div>
              </div>
              <div className="question-options">
                <div className="form-group">
                  <label className="label-primary">Answer Option 3</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form--inline-radio">
                  <label className="custom__radio lg">
                    <input className="custom__radio--input" type="radio" />
                    <span className="custom__radio--check lg"></span>
                  </label>
                </div>
              </div>
              <div className="question-options">
                <div className="form-group">
                  <label className="label-primary">Answer Option 4</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form--inline-radio">
                  <label className="custom__radio lg">
                    <input className="custom__radio--input" type="radio" />
                    <span className="custom__radio--check lg"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="label-primary">Quiz Background Photo</label>
              {/* <Dropzone
                accept="image/*"
                multiple={true}
                disabled={this.state.previewArr.length >= 50}
                onDrop={acceptedFiles =>
                  this.addImages(acceptedFiles, values.images, setFieldValue)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div className="dropzone__area" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <button type="button" className="btn btn__green">
                        {this.state.loader ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <Plus className="btn__icon" /> Upload Photo
                          </>
                        )}
                      </button>
                    </div>
                  </section>
                )}
              </Dropzone> */}
              <>
                <div className="invalid-feedback">
                  Please upload an image that in 10mb or smaller. You can use
                  the following compressors to make shrink your images:
                </div>
              </>
              <div className="invalid-feedback">Maximum 50 photos</div>
              <div className="dropzone__grid">
                <div className="dropzone__grid--item">
                  <div className="dropzone__grid--preview">
                    <img className="preview__thumb" src="" alt="" />
                    <div className="dropzone__grid--remove">
                      <button className="btn btn__remove">
                        <Remove />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="react-modal-footer">
            <button className="btn btn__default" onClick={closeScreenModal}>
              Cancel
            </button>
            <button type="submit" className="btn btn__purple">
              Update
            </button>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Quiz Cover Screen</h5>
            {/* <button className="btn react-modal-close" onClick={closeModal}><Close /></button> */}
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">Heading</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="label-primary">Sub Text</label>
              <textarea type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="label-primary">Button Text</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="label-primary">Quiz Background Photo</label>
              {/* <Dropzone
                accept="image/*"
                multiple={true}
                disabled={this.state.previewArr.length >= 50}
                onDrop={acceptedFiles =>
                  this.addImages(acceptedFiles, values.images, setFieldValue)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div className="dropzone__area" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <button type="button" className="btn btn__green">
                        {this.state.loader ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <Plus className="btn__icon" /> Upload Photo
                          </>
                        )}
                      </button>
                    </div>
                  </section>
                )}
              </Dropzone> */}
              <>
                <div className="invalid-feedback">
                  Please upload an image that in 10mb or smaller. You can use
                  the following compressors to make shrink your images:
                </div>
              </>
              <div className="invalid-feedback">Maximum 50 photos</div>
              <div className="dropzone__grid">
                <div className="dropzone__grid--item">
                  <div className="dropzone__grid--preview">
                    <img className="preview__thumb" src="" alt="" />
                    <div className="dropzone__grid--remove">
                      <button className="btn btn__remove">
                        <Remove />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="react-modal-footer">
            <button className="btn btn__default" onClick={closeModal}>
              Cancel
            </button>
            <button type="submit" className="btn btn__purple">
              Update
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
