import { connect } from "react-redux";
import {
  updateTags,
  getLtWeeklyThemeCategories,
  removeAgoloNews,
  getAgoloNewsByDate,
  getCuratedTags,
  addCuratedTags,
  UpdateLTWeeklyOthersNews,
  updateArticles,
  getCampaignClickInfo,
  getCampaignClickUrlsInfo,
  GetCampaignDetailsById,
  CampaignSearch
} from "../../store/home/duck";
import CampaignComponent from "./component";

const CampaignContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    CampaignSearch,
    GetCampaignDetailsById,
    getCampaignClickUrlsInfo,
    getCampaignClickInfo,
    UpdateLTWeeklyOthersNews,
    updateArticles,
    updateTags,
    getLtWeeklyThemeCategories,
    removeAgoloNews,
    getCuratedTags,
    addCuratedTags,
    getAgoloNewsByDate,
  }
)(CampaignComponent);

export default CampaignContainer;
