import { connect } from "react-redux";
import AddLtWeeklyComponent from "./component";
import {
  getSendEmailInformations,
  getMailingList,
  AddLTWeekly,
  uploadImage,
  sendFreeFormLtEmail,
} from "../../store/home/duck";
const AddLtWeeklyContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    getSendEmailInformations,
    getMailingList,
    AddLTWeekly,
    uploadImage,
    sendFreeFormLtEmail,
  }
)(AddLtWeeklyComponent);

export default AddLtWeeklyContainer;
