import React from "react";
// import PropTypes from 'prop-types'

export default function CheckboxComponent(props) {
  let d = props?.featureArray?.findIndex(
    (u) =>
      u?.Id === parseInt(props?.value?.Id) ||
      u?.AppUserId === parseInt(props?.value?.Id)
  );
  return (
    <>
      <label className="custom__checkbox">
        {" "}
        {props?.value?.FirstName} {props?.value?.LastName}
        <input
          className="custom__checkbox--input"
          type="checkbox"
          id={props?.index}
          value={props?.value?.Id}
          onChange={props.handleFeature}
          checked={d === -1 ? false : true}
        />
        <span className="custom__checkbox--check"></span>
      </label>
    </>
  );
}
