import React from "react";
import { Menu, Dropdown } from "antd";
import { More } from "../../svgIcons";

import "./styles.scss";

const options = (
  <Menu>
    <Menu.Item key="1">Archive</Menu.Item>
    <Menu.Item key="2" danger>
      Delete
    </Menu.Item>
  </Menu>
);

export default function ActionMoreComponent() {
  return (
    <>
      <Dropdown overlay={options} placement="bottomRight">
        <a
          className="btn ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          <More />
        </a>
      </Dropdown>
    </>
  );
}
