import { connect } from "react-redux";
import { removeAgoloNews, generatePdf, SendEmailToLtTeam, AddLTWeekly, sendLtWeeklyEmail, getMailingList, removeThemeById, uploadImage, updateLtweeklyDetails, getLtWeeklyItems, getAgoloNewsByDate, getCuratedTags, addCuratedTags, getMe, getEvents, getAppUsers,getAgoloNewsByDateNew,getAllCreatedAgoloNewsDate,AddLTWeeklyAuto,UpdateLtweeklyDetailsAuto,UpdateLtweeklyItem,AddPoll,GetPollUserDetails,GetPollByLtweeklyId } from "../../store/home/duck";
import AdminComponent from "./component";

const AdminComponentContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {    
    generatePdf,
    // syncLtWeeklyToSitecore,
    SendEmailToLtTeam,
    AddLTWeekly,
    removeAgoloNews,
    sendLtWeeklyEmail,
    getMailingList,
    removeThemeById,
    uploadImage,
    updateLtweeklyDetails,
    getLtWeeklyItems,
  	getCuratedTags,
  	addCuratedTags,
  	getAgoloNewsByDate,
    getAgoloNewsByDateNew,
    getAppUsers,
    getEvents,
    getMe,
    getAllCreatedAgoloNewsDate,
    AddLTWeeklyAuto,
    UpdateLtweeklyDetailsAuto,
    UpdateLtweeklyItem,
    AddPoll,
    GetPollUserDetails,
    GetPollByLtweeklyId
  }
)(AdminComponent);

export default AdminComponentContainer;
