import React, { useEffect, useState } from "react";
import Close from "../../components/svgIcons/Close";
import GeneralComponent from "./General/component";
import Attendees from "./Attendees/component";
import Agenda from "./Agenda/component";
import EpisodesSeasons from "./EpisodesSeasons/component";
import Activities from "./Activities/component";
import PhotosVideos from "./PhotosVideos/component";
import PhotoVideos from "./PhotoVideos/component";
import EventMap from "./EventMap/component";
import Quiz from "./Quiz/component";
import SkiResults from "./SkiResults/component";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// import Styles from "./showDetails.module.css"

export default function ShowDetailsComponent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [eventData, setEventData] = useState({});
  const [eventId, setEventId] = useState(0);
  const [from, setFrom] = useState("events");
  const [tab, setTab] = useState("general");
  const [eventSettingData, setEventSettingData] = useState({});
  const [isCalled, setIsCalled] = useState(false);
  const callApi = async (id) => {
    const { value } = await props.getEventDetails({ Id: id });
    if (value) {
      setEventData(value?.Data);
    }
  };
  const callEventSettingApi = async (id) => {
    const { value } = await props.getEventSettings({ Id: id });
    if (value) {
      setEventSettingData(value?.Result);
    }
  };
  useEffect(() => {
    const { state } = location;
    if (location.pathname.includes("/event-details/general")) {
      changeTab("general");
    } else if (location.pathname.includes("/event-details/agenda")) {
      changeTab("agenda");
    } else if (location.pathname.includes("/event-details/quiz")) {
      changeTab("quiz");
    } else if (location.pathname.includes("/event-details/attendees")) {
      changeTab("attendees");
    } else if (location.pathname.includes("/event-details/activities")) {
      changeTab("activities");
    } else if (location.pathname.includes("/event-details/ski-results")) {
      changeTab("ski-results");
    } else if (location.pathname.includes("/event-details/photo-video")) {
      changeTab("photo-video");
    } else if (location.pathname.includes("/event-details/event-map")) {
      changeTab("event-map");
    }
    if (!isCalled) {
      setFrom(state?.from);
      setIsCalled(true);
      setEventId(params?.Id);
      callApi(parseInt(params?.Id));
      callEventSettingApi(parseInt(params?.Id));
    }
  }, [isCalled]);
  const changeTab = (tab) => {
    setTab(tab);
    if (tab === "general") {
      navigate(`/event-details/general/${eventId}`);
    } else if (tab === "agenda") {
      navigate(`/event-details/agenda/${eventId}`);
    } else if (tab === "quiz") {
      navigate(`/event-details/quiz/${eventId}`);
    } else if (tab === "attendees") {
      navigate(`/event-details/attendees/${eventId}`);
    } else if (tab === "activities") {
      navigate(`/event-details/activities/${eventId}`);
    } else if (tab === "ski-results") {
      navigate(`/event-details/ski-results/${eventId}`);
    } else if (tab === "photo-video") {
      navigate(`/event-details/photo-video/${eventId}`);
    } else if (tab === "event-map") {
      navigate(`/event-details/event-map/${eventId}`);
    }
  };

  const updateEventSettingData = async (event) => {
    let status = eventSettingData[event.target.name];
    eventSettingData[event.target.name] = !status;
    setEventSettingData({ ...eventSettingData });
    const { value } = await props.updateEventSettings(eventSettingData);
  };
  return (
    <>
      <main className="main" role="head">
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => {
                        from === "appusers"
                          ? navigate("/app-users")
                          : navigate("/events");
                      }}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">Events Details</div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="tabs__links tabs__links--space">
          <button
            onClick={() => changeTab("general")}
            className={tab === "general" ? "tabs__link active" : "tabs__link"}
          >
            General
          </button>
          <button
            onClick={() => changeTab("agenda")}
            className={tab === "agenda" ? "tabs__link active" : "tabs__link"}
          >
            Agenda
          </button>
          <button
            onClick={() => changeTab("attendees")}
            className={tab === "attendees" ? "tabs__link active" : "tabs__link"}
          >
            Attendees
          </button>
          <button
            onClick={() => changeTab("activities")}
            className={
              tab === "activities" ? "tabs__link active" : "tabs__link"
            }
          >
            Activities
          </button>
          <button
            onClick={() => changeTab("ski-results")}
            className={
              tab === "ski-results" ? "tabs__link active" : "tabs__link"
            }
          >
            Ski Results
          </button>
          <button
            onClick={() => changeTab("photo-video")}
            className={
              tab === "photo-video" ? "tabs__link active" : "tabs__link"
            }
          >
            Photos & Videos
          </button>
          <button
            onClick={() => changeTab("event-map")}
            className={tab === "event-map" ? "tabs__link active" : "tabs__link"}
          >
            Event Map
          </button>
          <button
            onClick={() => changeTab("quiz")}
            className={tab === "quiz" ? "tabs__link active" : "tabs__link"}
          >
            Quiz
          </button>
        </div>
        {tab === "agenda" && (
          <Agenda
            {...props}
            eventSettingData={eventSettingData}
            updateEventSettingData={updateEventSettingData}
          />
        )}
        {tab === "attendees" && (
          <Attendees
            {...props}
            eventSettingData={eventSettingData}
            updateEventSettingData={updateEventSettingData}
          />
        )}
        {tab === "general" && (
          <GeneralComponent
            {...props}
            data={eventData}
            eventSettingData={eventSettingData}
            updateEventSettingData={updateEventSettingData}
          />
        )}

        {tab === "activities" && (
          <Activities
            {...props}
            eventSettingData={eventSettingData}
            updateEventSettingData={updateEventSettingData}
          />
        )}
        {tab === "photo-video" && (
          <>
            <PhotosVideos
              {...props}
              eventSettingData={eventSettingData}
              updateEventSettingData={updateEventSettingData}
            />
            {/* <PhotoVideos /> */}
          </>
        )}
        {tab === "event-map" && (
          <EventMap
            {...props}
            eventSettingData={eventSettingData}
            updateEventSettingData={updateEventSettingData}
          />
        )}
        {tab === "quiz" && (
          <Quiz
            {...props}
            eventSettingData={eventSettingData}
            updateEventSettingData={updateEventSettingData}
          />
        )}
        {tab === "ski-results" && (
          <SkiResults
            {...props}
            eventSettingData={eventSettingData}
            updateEventSettingData={updateEventSettingData}
          />
        )}
      </main>
    </>
  );
}
