import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// import Styles from "./profile.module.css"
import { Close } from "./../../components/svgIcons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import PhoneInput, {
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber
} from "react-phone-number-input";
import ReactModal from "react-modal";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
export default function ProfileComponent(props) {
  const [data, setData] = useState({});
  const [dataOld, setDataOld] = useState({});
  const location = useLocation()
  const param = useParams()
  const [emailError, setEmailError] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [countryCode, setCountryCode] = useState("US");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstNameError, setFirstNameError] = useState("")
  const [lastNameError, setLastNameError] = useState("")
  const [isLoading, setIsloading] = useState(false);
  const [isCalled, setIsCalled] = useState(false);
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [alternateemailError, setAlternateemailError] = useState("");

  const extractCountryCode = (countryCode) => {
    try {
      return "+" + getCountryCallingCode(countryCode);
    } catch (err) {}
    return "+";
  };
  const extractPhone = (countryCode = "", phoneNumber = "") => {
    return phoneNumber.replace(countryCode, "");
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const validateEmail = (email) => {
     // regular expression to validate email
     const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
     return re.test(email)
   }

  const handleChange = (name, value) => {
    data[name] = value;
    setData({ ...data });
  };
  const callGetUserDetails = async(id) => {
    const { value } = await props.getUserDetails(parseInt(id));
    setPhoneNumber(value.PhoneNumber)
    setData(value);
    setDataOld({...value})
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Liontree",
      description: message,
    });
  };

   // validate phone number
   function phoneValidation() {
    var countryDialCode = extractCountryCode(countryCode);
    var phoneNumberExtract = "";
    if (phoneNumber !== undefined) {
      phoneNumberExtract = extractPhone(countryDialCode, phoneNumber);
    }
    var obj = {};
    if (phoneNumberExtract === "") {
      obj.status = false;
    } else {
      obj.status = true;
    }
    obj.phone = phoneNumberExtract;
    obj.cc = countryDialCode;
    return obj;
  }

  useEffect(() => {
    if (!isCalled) {
      setIsCalled(true);
      callGetUserDetails(param?.id);
    }
  }, []);

  const deleteUser = async() => {
    let isDeleted = data.IsDeleted;
    // console.log(dataOld,'data')

    // data.SSOEmail = dataOld.SSOEmail
    // data.AlternateEmail = dataOld.AlternateEmail
    // data.FirstName = dataOld.FirstName
    // data.lastName = dataOld.lastName
    data.IsDeleted = !data.IsDeleted
    if(data?.IIMWeeklies === null){
      data.IIMWeeklies = 0
    }
    if(data?.CapitalWeeklies === null){
      data.CapitalWeeklies = 0
    }
    if(data?.IsDeleted === null){
      data.IsDeleted = 1
    }else{
      dataOld.IsDeleted = !dataOld.IsDeleted
    }
    dataOld.IIMWeeklies = data.IIMWeeklies
    dataOld.CapitalWeeklies = data.CapitalWeeklies
    const { value } = await props.editProfile(dataOld);
    if(value?.Success){
      setModalIsOpen(false)
      // callGetUserDetails(param?.id);
      if(isDeleted){
        openNotificationWithIcon("success","User Undeleted successfully.")
      }else{
        openNotificationWithIcon("success","User deleted successfully.")
        navigate('/administration/users')
        // openNotificationWithIcon("success", value.Message);
      }
    }else{
      setModalIsOpen(false)
      openNotificationWithIcon("error","Something went wrong")
    }
  }

  const updateUser = async () => {
    let phoneData = phoneValidation();
    if (!get(data, "FirstName", "")) {
      setFirstNameError("PLease enter FirstName")
    }else if (!get(data, "lastName", "")) {
      setLastNameError("PLease enter lastName")
    } 
    // else if (!get(data, "SSOEmail", "")) {
    //   setEmailError("Please enter email")
    // } 
    else if(get(data, "SSOEmail", "") !== "" && !validateEmail(get(data, "SSOEmail", ""))){
      setEmailError("Please enter valid email.")
    }else if(get(data, "AlternateEmail", "") !== ""&&!validateEmail(get(data, "AlternateEmail", ""))){
        setAlternateemailError("Please enter valid email.")
    } else if (phoneData?.phone === "") {
      setPhoneError("PLease enter phone number")
    } else if (!isValidPhoneNumber(`${phoneData.cc+phoneData.phone}`) ){
      setPhoneError("PLease enter valid number")
    }
    else {
      setIsloading(true);
      data.PhoneNumber = phoneData.cc + phoneData.phone
      if(data?.IIMWeeklies === null){
        data.IIMWeeklies = 0
      }
      if(data?.CapitalWeeklies === null){
        data.CapitalWeeklies = 0
      }
      if(data?.IsDeleted === null){
        data.IsDeleted = 0
      }
      let { value } = await props.editProfile(data);
      if (value.Success) {
        setIsloading(false);
        openNotificationWithIcon("success", value.Message);
      }else{
        setIsloading(false);
        openNotificationWithIcon("error", value.Message);
      }
      callGetUserDetails(param?.id);
    }
  };
  const navigate = useNavigate();

  return (
    <main className="main" role="tabs">
    <div className="main__header">
      <header className="head">
        <div className="head__container">
          <div className="head__row">
            <div className="head__left">
              <div className="head__close">
                <button
                  onClick={() => navigate("/administration/users")}
                  className="btn btn__close"
                >
                  <Close />
                </button>
              </div>
              <div className="head__divider"></div>
              <div className="head__title">User Information</div>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div className="custom__container">
    <div className="custom__wrap">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="label-primary">
              First Name<span className="required">*</span>
            </label>
            <input
              type="text"
              name="FirstName"
              onChange={(e) => {
                setFirstNameError("")
                handleChange(e.target.name, e.target.value)
              }}
              value={data?.FirstName}
              className="form-control"
              required
            />
          {firstNameError !== "" && (
              <div className="invalid-feedback">{firstNameError}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="label-primary">
              Last Name<span className="required">*</span>
            </label>
            <input
              type="text"
              name="lastName"
              onChange={(e) => {
                setLastNameError("")
                handleChange(e.target.name, e.target.value)
              }}
              value={data?.lastName}
              className="form-control"
              required
            />
            {lastNameError !== "" && (
              <div className="invalid-feedback">{lastNameError}</div>
            )}{" "}
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="label-primary">
          SSO Email
        </label>
        <input
          type="text"
          value={data?.SSOEmail}
          onChange={(e) => {
            setEmailError("")
            handleChange(e.target.name, e.target.value)
          }}
          name="SSOEmail"
          className="form-control"
          required
        />
        {emailError !== "" && (
          <div className="invalid-feedback">{emailError}</div>
        )}{" "}
      </div>
      <div className="form-group">
        <label className="label-primary">
        Alternative Email<span className="required"></span>
        </label>
        <input
          type="text"
          value={data?.AlternateEmail}
          onChange={(e) => {
            setAlternateemailError("")
            handleChange(e.target.name, e.target.value)
          }}
          name="AlternateEmail"
          className="form-control"
          required
        />
        {alternateemailError !== "" && (
          <div className="invalid-feedback">{alternateemailError}</div>
        )}{" "}
      </div>
      <div className="form-group">
          <label for="exampleFormControlInput1" class="label-primary">
            Phone Number
          </label>
          <PhoneInput
            // ref={phone_input}
            inputProps={{
              readOnly: false,
              name: "phone",
              required: true,
              autoFocus: true,
            }}
            className="international-phone"
            defaultCountry="US"
            country={"us"}
            value={phoneNumber}
            onChange={(val) => {
              setPhoneNumber(val);
              setPhoneError("")
            }}
            onCountryChange={(cc) => setCountryCode(cc)}
            limitMaxLength="10"
          />
          {phoneError !== "" && (
            <div className="invalid-feedback">{phoneError}</div>
          )}
      </div>
      <div> Access</div>
      <div className="form-group">
        <ul className="form-list-item removeBorder">
          <li>
            <span>IIM Weeklies Tab</span>
            <label className="custom__checkbox--only">
              <input
                type="checkbox"
                checked={data?.IIMWeeklies}
                name="IIMWeeklies"
                onChange = {()=>handleChange("IIMWeeklies", !data?.IIMWeeklies)}
                className="custom__checkbox--only-input"
              />
              <span className="custom__checkbox--only-check"></span>
            </label>
          </li>
          <li>
            <span>Capital Markets Tab</span>
            <label className="custom__checkbox--only">
              <input
                type="checkbox"
                name="CapitalWeeklies"
                checked={data?.CapitalWeeklies}
                onChange = {()=>handleChange("CapitalWeeklies",!data?.CapitalWeeklies)}
                className="custom__checkbox--only-input"
              />
              <span className="custom__checkbox--only-check"></span>
            </label>
          </li>
        </ul>
      </div> 
      <button
        type="submit"
        className="btn btn-lg btn__purple w-100"
        onClick={() => updateUser()}
      >
        {isLoading ? <Spin indicator={antIcon} /> : "Save"}
      </button>
      <br/>
      <br/>
      {!data?.IsDeleted ?
        <div className="text-center">
          <button
            onClick={() => setModalIsOpen(true)}
            className="btn btn-danger btn-lg w-150"
          >
            Delete User
          </button>
        </div>
        :
        <div className="text-center">
          <button
            onClick={() => setModalIsOpen(true)}
            className="btn btn-danger btn-lg w-150"
          >
            UnDelete User
          </button>
        </div>
      }
      </div>
    </div>
    <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        contentLabel="Are you sure?"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title react-modal-title-md">Delete</h5>
            <p className="react-modal-subtitle">
              Are you sure you want to delete this? This cannot be undone.
            </p>
            <button
              type="button"
              className="btn react-modal-close"
              onClick={() => closeModal()}
            >
              <Close />
            </button>
          </div>
          {!data?.IsDeleted ?
            <div className="react-modal-body">
              <div className="text-right d-flex align-items-center justify-content-center">
                <button
                  onClick={() => deleteUser()}
                  className="btn btn-danger btn-lg w-150"
                >
                  Yes, Delete User
                </button>
              </div>
            </div>
          :
            <div className="react-modal-body">
              <div className="text-right">
                <button
                  onClick={() => deleteUser()}
                  className="btn btn-danger btn-lg w-150"
                >
                  Yes, UnDelete User
                </button>
              </div>
            </div>
          }
        </div>
      </ReactModal>
  </main>
  
  );
}
