import React, { useEffect, useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import { Delete, Plus, Close, Remove } from "../../../components/svgIcons";
import ActionMore from "../../../components/common/ActionMore/component";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ReactModal from "react-modal";
import CheckboxComponent from "../../../components/common/ui/CheckBox";
let cancelToken;
let cancelTokenUserList;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
export default function AttendeesComponent(props) {
  const navigate = useNavigate();
  const params = useParams();
  const eventId = parseInt(params?.Id);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [appUsers, setAppUsersData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isCalledApi, setIsCalled] = useState(false);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(true);
  const [loaderUser, setLoaderUser] = useState(true);
  const [nextStep, setNextStep] = useState(0);
  const [userQuery, setUserQuery] = useState("");
  const [page, setPage] = useState(1);
  const [removeEventAppUsers, setRemoveEventAppUsers] = useState([]);
  const [newEventAppUsers, setNewEventAppUsers] = useState([]);
  const [updateEventAttendeesData, setUpdateEventAttendeesData] = useState([]);
  const [eventAttendeesData, setEventAttendeesData] = useState([]);
  const [totalEventCount, setTotalEventAttendeesCount] = useState(0);
  const [eventAppUsers, setEventAppUsers] = useState([]);
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const callApi = async () => {
    if (typeof cancelTokenUserList != typeof undefined) {
      cancelTokenUserList.cancel("Operation canceled due to new request.");
    }
    // save the cancel token for the current request
    cancelTokenUserList = axios.CancelToken.source();
    const { value } = await props.getAppUsers({
      count: 10,
      page: 1,
      query: "",
      token: cancelTokenUserList.token,
    });
    if (value) {
      setAppUsersData(value?.Data);
      setLoaderUser(false);
    }
  };

  const pageChange = async (value) => {
    if (value === "next") {
      let pageNum = parseInt(page + 1);
      setPage(pageNum);
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      // save the cancel token for the current request
      cancelToken = axios.CancelToken.source();
      const { value } = await props.getEventAttendees({
        count: 100,
        page: pageNum,
        eventId: parseInt(params?.Id),
        query,
        token: cancelToken.token,
      });
      if (value) {
        setLoader(false);
        let dataUser = [];
        // let prm = value?.Data?.map((d, i) => {
        //   dataUser[i].push({
        //     AppUser: d,
        //   });
        // });
        // await Promise.all(prm);
        setEventAppUsers(value?.Data); // let data = appUsersData.concat(value?.Data);
        setEventAttendeesData(value?.Data);
        setTotalEventAttendeesCount(value?.TotalCount);
      }
    } else if (value === "previous") {
      let pageNum = page - 1 === 0 ? 1 : page - 1;
      setPage(pageNum);
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      // save the cancel token for the current request
      cancelToken = axios.CancelToken.source();
      const { value } = await props.getEventAttendees({
        count: 100,
        page: pageNum,
        eventId: parseInt(params?.Id),
        query,
        token: cancelToken.token,
      });
      if (value) {
        setLoader(false);
        // let data = appUsersData.concat(value?.Data);
        setEventAttendeesData(value?.Data);
        setTotalEventAttendeesCount(value?.TotalCount);
        let dataUser = [];
        let prm = value?.Data?.map((d, i) => {
          dataUser[i].push({
            AppUser: d,
          });
        });
        await Promise.all(prm);
        // setEventAppUsers(dataUser);
        setEventAppUsers(value?.Data); // let data = appUsersData.concat(value?.Data);
      }
    }
  };

  const handleChange = async (q) => {
    setLoader(true);
    setQuery(q);
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    // save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    const { value } = await props.getEventAttendees({
      count: 100,
      page: page,
      query: q,
      eventId: eventId,
      token: cancelToken.token,
    });
    if (value) {
      // let data = appUsersData.concat(value?.Data);
      setEventAttendeesData(value?.Data);
      setTotalEventAttendeesCount(value?.TotalCount);
      let dataUser = [];
      // let prm = value?.Data?.map((d, i) => {
      //   dataUser[i].push({
      //     AppUser: d,
      //   });

      // });
      // await Promise.all(prm);
      // setEventAppUsers(dataUser);
      setLoader(false);

      setEventAppUsers(value?.Data); // let data = appUsersData.concat(value?.Data);
      let time = setInterval(() => {
        setLoader(false);
        clearInterval(time);
      }, 1000);
    } else {
      // setLoader(false);
    }
  };

  const callEventAttendeesApi = async (id) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    // save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    const { value } = await props.getEventAttendees({
      eventId: parseInt(params?.Id),
      count: 100,
      page: 1,
      query: "",
      token: cancelToken.token,
    });
    if (value?.Success) {
      let nextPageCount = parseInt(Math.ceil(value?.TotalCount / 100));
      setNextStep(nextPageCount);
      setTotalEventAttendeesCount(value?.TotalCount);
      setEventAttendeesData(value?.Data);
      setLoader(false);
      // let dataUser = [];
      // let prm = value?.Data?.map((d, i) => {
      //   dataUser[i].push({
      //     AppUser: d,
      //   });
      // });
      // await Promise.all(prm);
      setEventAppUsers(value?.Data);
      // setEventAppUsers(value?.Data); // let data = appUsersData.concat(value?.Data);
    }
  };
  const removeAppUserToEvent = async (dataAppUser) => {
    const { value } = await props.removeAppUserToEventAttendees(dataAppUser);
    if (value) {
      setRemoveEventAppUsers([]);
      callEventAttendeesApi(parseInt(params?.Id));
      //  openNotificationWithIcon(
      //    "success",
      //    "Updated event details successfully."
      //  );
      //  setIsOpen(false);
      //  // setEventAttendeesData(dataAppUser);
      //  setIsLoading(false);
    }
  };
  const callAddUserToEvent = async () => {
    setIsLoading(true);
    let dataAppUser = [];
    let prmd = newEventAppUsers?.map((e) => {
      let d = {
        EventId: parseInt(params?.Id),
        AppUserId: parseInt(e?.AppUserId)
          ? parseInt(e?.AppUserId)
          : parseInt(e?.Id),
        Id: parseInt(e?.Id) ? parseInt(e?.Id) : parseInt(e?.AppUserId),

        ManagerActiveStatus: true,
      };
      dataAppUser.push(d);
    });
    await Promise.all(prmd);
    let removeAppUser = [];
    let prmr = removeEventAppUsers?.map((e) => {
      let d = {
        EventId: parseInt(params?.Id),
        AppUserId: parseInt(e?.AppUserId)
          ? parseInt(e?.AppUserId)
          : parseInt(e?.Id),
        Id: parseInt(e?.Id) ? parseInt(e?.Id) : parseInt(e?.AppUserId),
        // AppUserId: parseInt(e?.AppUserId),
        ManagerActiveStatus: true,
        ArchiveMessages: false,
      };
      removeAppUser.push(d);
    });
    await Promise.all(prmr);

    if (removeAppUser.length > 0) {
      await removeAppUserToEvent(removeAppUser);
    }
    const { value } = await props.addAppUserToEventAttendees(dataAppUser);
    if (value) {
      setNewEventAppUsers([]);
      callEventAttendeesApi(parseInt(params?.Id));
      openNotificationWithIcon(
        "success",
        "Updated event details successfully."
      );
      setIsOpen(false);
      // setEventAttendeesData(dataAppUser);
      setIsLoading(false);
    }
  };
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Liontree",
      description: message,
    });
  };
  useEffect(() => {}, [setEventAttendeesData]);
  useEffect(() => {
    if (!isCalledApi || eventAttendeesData?.length === 0) {
      setIsCalled(true);
      if (parseInt(params?.Id)) {
        callApi();
        callEventAttendeesApi(parseInt(params?.Id));
      }
    }
  }, [isCalledApi]);
  const optimizedFn = useCallback(debounce(handleChange), []);

  const handleCheck = (val, index, name) => {
    let test = eventAttendeesData;
    test[index][name] = !val;
    setEventAttendeesData([...test]);

    let dataNew = eventAttendeesData[index];
    let data = updateEventAttendeesData.find((d) => d?.Id === dataNew.Id);
    if (data === undefined) {
      setUpdateEventAttendeesData([...updateEventAttendeesData, dataNew]);
    }
  };

  const updateAttendeesPermissions = async () => {
    setIsLoading(true);
    const { value } = await props.updateAttendeesPermissions(
      updateEventAttendeesData
    );
    if (value) {
      openNotificationWithIcon(
        "success",
        "Updated event details successfully."
      );
      setIsLoading(false);
    }
  };

  const handleUserChange = async (name, q) => {
    setLoaderUser(true);
    setUserQuery(q);
    if (typeof cancelTokenUserList != typeof undefined) {
      cancelTokenUserList.cancel("Operation canceled due to new request.");
    }
    // save the cancel token for the current request
    cancelTokenUserList = axios.CancelToken.source();
    const { value } = await props.getAppUsers({
      count: 10,
      page: 1,
      query: q,
      token: cancelTokenUserList.token,
    });
    if (value) {
      // let data = appUsersData.concat(value?.Data);
      setAppUsersData(value?.Data);
      setLoaderUser(false);
    } else {
      setAppUsersData([]);
      setLoaderUser(false);
    }
  };

  const handleUsers = (e) => {
    let test = eventAppUsers;
    let index = eventAppUsers?.findIndex(
      (u) =>
        u?.Id === parseInt(e.target.value) ||
        u?.AppUserId === parseInt(e.target.value)
    );
    if (index > -1) {
      let data = eventAppUsers.find(
        (u) =>
          u?.Id === parseInt(e.target.value) ||
          u?.AppUserId === parseInt(e.target.value)
      );
      test.splice(index, 1);
      setEventAppUsers([...test]);
      setRemoveEventAppUsers([...removeEventAppUsers, data]);
    } else {
      let data = appUsers.find(
        (u) => u?.AppUser?.Id === parseInt(e.target.value)
      );
      setNewEventAppUsers([...newEventAppUsers, data?.AppUser]);
      setEventAppUsers([...test, data?.AppUser]);
    }
    // console.log(test, "test");
    // setEventAppUsers(test);
    setSelectedUsers(test);
  };
  function closeModal() {
    setIsOpen(false);
  }
  const optimizedUserFn = useCallback(debounce(handleUserChange), []);
  return (
    <>
      <div className="page__header">
        <div className="page__header--container">
          <div className="page__header--row align-items-center">
            <div className="page__header--left">
              <label className="custom__checkbox">
                <input
                  name="ShowAttendeesListInApp"
                  onChange={(e) => props?.updateEventSettingData(e)}
                  checked={props?.eventSettingData?.ShowAttendeesListInApp}
                  type="checkbox"
                  className="custom__checkbox--input"
                />
                <span className="custom__checkbox--check"></span>
                Show section tab in Mobile App{" "}
              </label>
            </div>

            {/* <div className="page__header--right">
            </div> */}
            <div className="page__header--right">
              <button
                onClick={() => updateAttendeesPermissions()}
                type="button"
                className="btn btn__purple me-2"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Save & Update"}
              </button>
              <button
                onClick={() => setIsOpen(true)}
                type="button"
                className="btn btn__purple"
              >
                <Plus className="btn__icon" /> Add New User to Event
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="custom__container">
        <div className="form-group">
          <input
            name="query"
            onChange={(e) => {
              setQuery(e.target.value);
              optimizedFn(e.target.value);
            }}
            // onChange={(e) => handleChange(e.target.name, e.target.value)}
            value={query}
            placeholder="Search by name, email, or phone number..."
            className="form-control"
          />
        </div>
        <div className="table-responsive table__responsive--custom">
          <table className="table table__custom">
            <thead>
              <tr>
                <th>Name</th>
                <th className="text-center">Presenter</th>
                <th className="text-center">
                  Can View List of Attendees in App
                </th>
                <th className="text-center">Archive Message</th>
                <th>Auto Connect to Other Attendees</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <Spin
                  size="large"
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 30, color: "#000" }}
                      spin
                    />
                  }
                  className="loader__full"
                />
              ) : (
                eventAttendeesData?.length > 0 &&
                eventAttendeesData?.map((u, i) => {
                  return (
                    <tr key={i}>
                      <td
                        onClick={() =>
                          navigate(`/app-user/${u?.Id}`, {
                            state: { data: u },
                          })
                        }
                        className="underline"
                      >
                        {u?.FirstName} {u?.LastName}
                      </td>
                      <td className="text-center">
                        <label className="custom__checkbox--only">
                          <input
                            name="IsPresenter"
                            onChange={() =>
                              handleCheck(u?.IsPresenter, i, "IsPresenter")
                            }
                            checked={u?.IsPresenter}
                            type="checkbox"
                            className="custom__checkbox--only-input"
                          />
                          <span className="custom__checkbox--only-check"></span>
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom__checkbox--only">
                          <input
                            name="CanSeeAttendeesList"
                            onChange={() =>
                              handleCheck(
                                u?.CanSeeAttendeesList,
                                i,
                                "CanSeeAttendeesList"
                              )
                            }
                            checked={u?.CanSeeAttendeesList}
                            type="checkbox"
                            className="custom__checkbox--only-input"
                          />
                          <span className="custom__checkbox--only-check"></span>
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom__checkbox--only">
                          <input
                            name="ArchiveMessages"
                            onChange={() =>
                              handleCheck(
                                u?.ArchiveMessages,
                                i,
                                "ArchiveMessages"
                              )
                            }
                            checked={u?.ArchiveMessages}
                            type="checkbox"
                            className="custom__checkbox--only-input"
                          />
                          <span className="custom__checkbox--only-check"></span>
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom__checkbox--only">
                          <input
                            name="AutoConnectToOtherAttendees"
                            onChange={() =>
                              handleCheck(
                                u?.AutoConnectToOtherAttendees,
                                i,
                                "AutoConnectToOtherAttendees"
                              )
                            }
                            checked={u?.AutoConnectToOtherAttendees}
                            type="checkbox"
                            className="custom__checkbox--only-input"
                          />
                          <span className="custom__checkbox--only-check"></span>
                        </label>
                      </td>
                      <td>
                        <ActionMore />
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>

        {/* <div className="table__blank">
          <div className="table__blank--text">No users</div>
        </div> */}

        {eventAttendeesData?.length <= 0 && (
          <div className="table__blank">
            <div className="table__blank--text">No Attendees</div>
          </div>
        )}
        {eventAttendeesData?.length > 0 && (
          <div className="table__pagination">
            <div className="table__pagination--results">
              {totalEventCount} results
            </div>
            <div className="table__pagination--nav">
              <>
                {page - 1 >= 1 ? (
                  <button
                    type="button"
                    className="btn btn__default table__pagination--prev"
                    onClick={() => pageChange("previous")}
                  >
                    Previous
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn__default table__pagination--prev disabled"
                  >
                    Previous
                  </button>
                )}
              </>
              <>
                {nextStep - page > 0 ? (
                  <button
                    type="button"
                    className="btn btn__default table__pagination--next"
                    onClick={() => pageChange("next")}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn__default table__pagination--next disabled"
                  >
                    Next
                  </button>
                )}
              </>
            </div>
          </div>
        )}
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header border-bottom">
            <h5 className="react-modal-title">App Users</h5>
            <button className="btn react-modal-close" onClick={closeModal}>
              <Close />
            </button>
          </div>
          <div className="react-modal-body react-modal-body-style-2">
            <div className="form-group">
              {/* <input
                  type="text"
                  name=""
                  placeholder="Search by name, email, or phone number..."
                  className="form-control"
                  required
                /> */}
              <input
                name="userQuery"
                onChange={(e) => {
                  setUserQuery(e.target.value);
                  optimizedUserFn(e.target.name, e.target.value);
                }}
                // onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={userQuery}
                placeholder="Search by name, email, or phone number..."
                className="form-control"
              />
            </div>
            <div className="margin-minus-15">
              <div className="modal-list-container h-md">
                {loaderUser ? (
                  <>
                    <div className="loader-container">
                      <Spin
                        size="large"
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 30, color: "#000" }}
                            spin
                          />
                        }
                        className=""
                      />
                    </div>
                  </>
                ) : (
                  appUsers?.length > 0 &&
                  appUsers?.map((option, index) => {
                    return (
                      <div key={index} className="modal-list-items">
                        <CheckboxComponent
                          index={index}
                          key={eventAppUsers}
                          value={option?.AppUser}
                          handleFeature={(e) => handleUsers(e)}
                          featureArray={eventAppUsers}
                        />
                      </div>
                    );
                  })
                )}
                {/* {appUsers?.length > 0 &&
                    appUsers?.map((u, i) => {
                      return (
                          <label className="custom__checkbox">
                            <input
                              type="checkbox"
                              className="custom__checkbox--input"
                            />
                            <span className="custom__checkbox--check"></span>
                            {u?.AppUsers?.FirstName} {u?.AppUsers?.LastName}
                          </label>
                        </div>
                      );
                    })} */}
                {appUsers?.length <= 0 && (
                  <div className="table__blank">
                    <div className="table__blank--text">No users</div>
                  </div>
                )}
                {/* <div className="modal-list-items">
                    <label className="custom__checkbox">
                      <input
                        type="checkbox"
                        className="custom__checkbox--input"
                      />
                      <span className="custom__checkbox--check"></span>
                      Show in Mobile App
                    </label>
                  </div>
                  <div className="modal-list-items">
                    <label className="custom__checkbox">
                      <input
                        type="checkbox"
                        className="custom__checkbox--input"
                      />
                      <span className="custom__checkbox--check"></span>
                      Show in Mobile App
                    </label>
                  </div> */}
              </div>
            </div>
          </div>
          <div className="react-modal-footer">
            <button
              onClick={() => callAddUserToEvent()}
              type="submit"
              className="btn btn-lg btn__purple w-100 ms-0"
            >
              {isLoading ? <Spin indicator={antIcon} /> : "Save & Update"}
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
