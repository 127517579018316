import React from "react";
import Dropzone from "react-dropzone";
import { HolderOutlined } from "@ant-design/icons";
import ReactModal from "react-modal";
import { Delete, Plus, Remove, Setting } from "../../../components/svgIcons";
import ActionMore from "../../../components/common/ActionMore/component";

<HolderOutlined />;

export default function ActivitiesComponent(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="page__header">
        <div className="page__header--container">
          <div className="page__header--row align-items-center">
            <div className="page__header--left">
              <label className="custom__checkbox">
                <input
                  name="ShowActivitiesInApp"
                  onChange={(e) => props?.updateEventSettingData(e)}
                  checked={props?.eventSettingData?.ShowActivitiesInApp}
                  type="checkbox"
                  className="custom__checkbox--input"
                />
                <span className="custom__checkbox--check"></span>
                Show section tab in Mobile App
              </label>
            </div>
            <div className="page__header--right">
              <button
                type="button"
                className="btn btn__default"
                onClick={openModal}
              >
                <Setting className="btn__icon" /> Activity Cover Photo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="custom__container">
        <div className="table-responsive table__responsive--custom">
          <table className="table table__custom items-order-table">
            <thead>
              <tr>
                <th>Activity Title</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="underline">
                  <HolderOutlined className="reorderIcon" /> Dinner Reception
                </td>
              </tr>
              <tr>
                <td className="underline">
                  <HolderOutlined className="reorderIcon" /> Dinner Reception
                  Dinner Reception Dinner Reception Dinner Reception
                </td>
              </tr>
              <tr>
                <td className="underline">
                  <HolderOutlined className="reorderIcon" /> Dinner Reception
                </td>
              </tr>
              <tr>
                <td className="underline">
                  <HolderOutlined className="reorderIcon" /> Dinner Reception
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <div className="table__blank">
          <div className="table__blank--text">No users</div>
        </div> */}

        <div className="table__pagination">
          <div className="table__pagination--results">3 results</div>
          <div className="table__pagination--nav">
            <button
              type="button"
              className="btn btn__default table__pagination--prev disabled"
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn__default table__pagination--next disabled"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Activity Cover Photo</h5>
            {/* <button className="btn react-modal-close" onClick={closeModal}><Close /></button> */}
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">Activty Cover Photo</label>
              {/* <Dropzone
                accept="image/*"
                multiple={true}
                disabled={this.state.previewArr.length >= 50}
                onDrop={acceptedFiles =>
                  this.addImages(acceptedFiles, values.images, setFieldValue)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div className="dropzone__area" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <button type="button" className="btn btn__green">
                        {this.state.loader ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <Plus className="btn__icon" /> Upload Photo
                          </>
                        )}
                      </button>
                    </div>
                  </section>
                )}
              </Dropzone> */}
              <>
                <div className="invalid-feedback">
                  Please upload an image that in 10mb or smaller. You can use
                  the following compressors to make shrink your images:
                </div>
              </>
              <div className="invalid-feedback">Maximum 50 photos</div>
              <div className="dropzone__grid">
                <div className="dropzone__grid--item">
                  <div className="dropzone__grid--preview">
                    <img className="preview__thumb" src="" alt="" />
                    <div className="dropzone__grid--remove">
                      <button className="btn btn__remove">
                        <Remove />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="react-modal-footer">
            <button className="btn btn__default" onClick={closeModal}>
              Cancel
            </button>
            <button type="submit" className="btn btn__purple">
              Save
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
