import React, { useState, useEffect } from "react";
import Logo from "../../components/svgIcons/Logo";
import PhoneInput, {
  getCountryCallingCode
} from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import S from "./login.module.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
export default function LoginComponent(props) {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [countryCode, setCountryCode] = useState("US");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberUnknownError, setPhoneNumberUnknownError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [errorMessage, setErrorMessage] = useState("")
  const [isVerified, setIsVerified] = useState(true)
  const [loader, setLoader] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const extractCountryCode = (countryCode) => {
    try {
      return "+" + getCountryCallingCode(countryCode);
    } catch (err) {}
    return "+";
  };
  const extractPhone = (countryCode = "", phoneNumber = "") => {
    return phoneNumber.replace(countryCode, "");
  };

  const callVerifyApi = async (email) => {
      setLoader(true)
      const { value } = await props.VerifyEmail({EmailId:email})
      if(value?.IsApproved){
        setLoader(false)
        localStorage.setItem("Authorization", value?.DetailMessage);
        navigate("/iim-weeklies");
      }else{
        setLoader(false)
        setIsVerified(false)
        setErrorMessage(value?.ShortMessage);
      }
  }

  useEffect(() => {
    if (localStorage.getItem("Authorization")) {
      navigate("/iim-weeklies");
    }
    const currentAccount = instance.getActiveAccount();
    if (currentAccount && !localStorage.getItem('Authorization')) {
      if(isVerified){
        callVerifyApi(currentAccount?.username)
      }
      // console.log(currentAccount, "currentAccount");
    }else{
      console.log(currentAccount)
    }
  });

  useEffect(() => {
    if (!isAuthenticated && isLogin) {
      // TODO: grab the username from query params
      instance
        .ssoSilent({
          scopes: ["user.read"],
          // loginHint: "diegos@msaltestingjs.onmicrosoft.com"
        })
        .then((response) => {
          callVerifyApi(response.account.username)
          instance.setActiveAccount(response.account);
        })
        .catch((error) => {
          console.log(error,'err')
          if (error instanceof InteractionRequiredAuthError) {
              setIsLogin(false)
          // // instance.loginRedirect({
          // //   scopes: ["user.read"],
          // // });
          }
        });
    }
  }, [instance, isAuthenticated]);

  // validate phone number
  function phoneValidation() {
    var countryDialCode = extractCountryCode(countryCode);
    var phoneNumberExtract = "";
    if (phoneNumber !== undefined) {
      phoneNumberExtract = extractPhone(countryDialCode, phoneNumber);
    }
    var obj = {};
    if (phoneNumberExtract === "") {
      obj.status = false;
    } else {
      obj.status = true;
    }
    obj.phone = phoneNumberExtract;
    obj.cc = countryDialCode;
    return obj;
  }

  const processOTP = async () => {
    setErrorMessage("")
    let phoneData = phoneValidation();
    if (phoneData?.phone === "") {
      setPhoneNumberUnknownError("Please enter phone number");
    } else {
      setIsLoading(true);

      let param = {
        PhoneNumber: phoneData.cc + phoneData.phone,
      };

      let { value } = await props.getOtp(param);
      if (value?.IsSuccess) {
        setIsLoading(false);
        navigate("/verify-code", {
          state: {
            phoneData: phoneData,
            step: "login_otp_verification",
            otpMessage: value?.ShortMessage,
          },
          replace: true,
        });
      } else {
        setIsLoading(false);
        setPhoneNumberUnknownError(value?.ShortMessage);
      }
      // console.log("dssddsa");
    }
  };

  const handleSignIn = () => {
    if(errorMessage !== ""){
      instance.logoutRedirect()
    }else{
      setErrorMessage("")
      setPhoneNumber("")
      setPhoneNumberUnknownError("")
      setIsVerified(true)
      // instance.logoutRedirect();
      setIsLogin(true)
      instance.loginRedirect({
        scopes: ["user.read"],
      });
    }
  };

  return (
    <>
    {loader ? 
    <Spin
      size="large"
      indicator={
        <LoadingOutlined
          style={{ fontSize: 30, color: "#000" }}
          spin
        />
      }
      className="loader__full"
    />
    :
      <main className={S.main}>
        <div className={S.wrapper}>
          <div className={S.head}>
            <div className={S.logo}>
              <Logo />
            </div>
            <h1 className={S.title}>LT Content Management Hub</h1>
          </div>
          <div className="form-group">
            <label for="exampleFormControlInput1" class="label-primary">
              Phone Number
            </label>
            <PhoneInput
              // ref={phone_input}
              inputProps={{
                readOnly: false,
                name: "phone",
                required: true,
                autoFocus: true,
              }}
              className="international-phone"
              defaultCountry="US"
              country={"US"}
              value={phoneNumber}
              onChange={(val) => {
                setPhoneNumber(val);
              setErrorMessage("")
              setPhoneNumberUnknownError("");
            }}
            onCountryChange={(cc) => setCountryCode(cc)}
            limitMaxLength="10"
          />
          {phoneNumberUnknownError !== "" && (
            <div className="invalid-feedback">{phoneNumberUnknownError}</div>
          )}

        <div className="mb-2 mt-1">
        <label className='custom__checkbox'>
          <input  value= {isChecked}
          type='checkbox'
          className='custom__checkbox--input'/>
          <span className='custom__checkbox--check'>
          
          </span>
          I agree to receive login code via sms 
          </label>
        </div>

        </div>

          <div>
            <button
              onClick={() => processOTP()}
              type="submit"
              className="btn btn-lg btn__purple w-100"
            >
              {isLoading ? <Spin indicator={antIcon} /> : "Request Login Code"}
            </button>
          </div>
      
        <hr/>
        <div>
          <button className="btn btn-lg btn__purple w-100" onClick={()=>handleSignIn()}> Login With LionTree Azure AD </button>
        </div>
        {errorMessage &&
          <div className="invalid-feedback">{errorMessage}</div>
        }
        </div>
      </main>

    }
    </>
  );
}
