import React from "react";
import Profile from "./Profile/component";
import { Close } from "./../../components/svgIcons";
import { useNavigate } from "react-router-dom";
// import Styles from "./account.module.css"

export default function AccountComponent(props) {
  const navigate = useNavigate();
  return (
    <>
      <main className="main" role="tabs">
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate("/bulk-tag")}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">Account Information</div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="custom__container">
          <div className="custom__wrap">
            <Profile {...props} />
          </div>
        </div>
      </main>
    </>
  );
}
