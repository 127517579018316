import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { PublicClientApplication, EventType } from '@azure/msal-browser';
const clientId = process.env.REACT_APP_CLIENT_ID;
const authority = process.env.REACT_APP_AUTHORITY;
const redirectUri = process.env.REACT_APP_REDIRECTURI;
const root = ReactDOM.createRoot(document.getElementById("root"));

const pca = new PublicClientApplication({
    auth: {
        clientId:clientId,
        authority: authority,
        redirectUri: redirectUri,
        navigateToLoginRequestUrl: true,
        validateAuthority: true,
        // protectedResourceMap: null
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    }
});

pca.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
    	localStorage.setItem('name', JSON.stringify(event.payload.account));
        pca.setActiveAccount(event.payload.account);
    }
});

root.render(
    <Provider store={store}>
       <App pca={pca}/>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
