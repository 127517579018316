import React from "react"
import ActionMore from "../../../components/common/ActionMore/component"
import { Close, Delete, Plus, Remove } from "../../../components/svgIcons"

export default function AppUsersComponent() {

  
  return (
    <>
      <div className="page__header">
        <div className="page__header--container">
          <div className="page__header--row align-items-center">
            <div className="page__header--left">
              <h1 className="page__header--title">App Users</h1>
            </div>
            <div className="page__header--right">
              <button type="button" className="btn btn__purple">
                <Plus className="btn__icon" /> Add New App User 
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="custom__container">
        <div className="form-group">
          <input 
            placeholder="Search by name, email, or phone number..."
            className="form-control"
          />
        </div>
        <div className="table-responsive table__responsive--custom">
          <table className="table table__custom">
            <thead>
              <tr>
                <th>Name</th>
                <th>Events</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Alex Adams</td>
                <td><span className="underline">Alex Adams, </span> <span className="underline">Alex Adams</span></td>
              </tr>
              <tr>
                <td>Alex Jane</td>
                <td><span className="underline">Alex Adams</span></td>
              </tr>
              <tr>
                <td>Deepak Devjani</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* <div className="table__blank">
          <div className="table__blank--text">No users</div>
        </div> */}

        <div className="table__pagination">
          <div className="table__pagination--results">3 results</div>
          <div className="table__pagination--nav">
            <button
              type="button"
              className="btn btn__default table__pagination--prev disabled"
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn__default table__pagination--next disabled"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
