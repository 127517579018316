import { connect } from "react-redux";
import {
  addUser,
  getEventDetails,
  addAppUserToEvent,
  updateAttendeesPermissions,
  removeAppUserToEventAttendees,
  addEvent,
  getEventSettings,
  updateEventSettings,
  addAppUserToEventAttendees,
  getEventAttendees,
  getAppUsers,
} from "../../store/home/duck";
import EventDetailComponent from "./component";

const EventDetailContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    addUser,
    updateAttendeesPermissions,
    removeAppUserToEventAttendees,
    getEventSettings,
    updateEventSettings,
    addAppUserToEventAttendees,
    getAppUsers,
    getEventAttendees,
    addAppUserToEvent,
    addEvent,
    getEventDetails,
  }
)(EventDetailComponent);

export default EventDetailContainer;
