import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TopNavigation from "../../components/common/TopNavigation/component";
import { Plus } from "../../components/svgIcons";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
let cancelToken;

export default function AdminComponent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCalled, setIsCalled] = useState(false);
  const [userData, setUserData] = useState({});
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(true);
  const [nextStep, setNextStep] = useState(0);
  const [eventNextStep, setEventNextStep] = useState(0);
  const [page, setPage] = useState(1);
  const [eventPage, setEventPage] = useState(1);
  const [appUsersData, setAppUsersData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [totalAppUserCount, setTotalAppUserCount] = useState(0);
  const [totalEventCount, setTotalEventCount] = useState(0);
  const [tab, setTab] = useState("events");
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  const changeTab = async (tab) => {
    setTab(tab);
    if (tab === "app") {
      navigate("/app-users");
      setLoader(true);
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      // save the cancel token for the current request
      cancelToken = axios.CancelToken.source();
      const { value } = await props.getAppUsers({
        count: 100,
        page: 1,
        query: "",
        token: cancelToken.token,
      });
      if (value) {
        let nextPageCount = parseInt(Math.ceil(value?.TotalCount / 100));
        setNextStep(nextPageCount);
        setAppUsersData(value?.Data);
        setTotalAppUserCount(value?.TotalCount);
        let time = setInterval(() => {
          setLoader(false);
          clearInterval(time);
        }, 1000);
      }
    } else if (tab === "events") {
      setLoader(true);
      navigate("/events");
      const { value } = await props.getEvents({
        count: 100,
        page: 1,
      });
      if (value) {
        let nextPageCount = parseInt(Math.ceil(value?.TotalCount / 100));
        setEventNextStep(nextPageCount);
        setEventsData(value?.Data);
        setTotalEventCount(value?.TotalCount);
        let time = setInterval(() => {
          setLoader(false);
          clearInterval(time);
        }, 500);
      }
    }
  };

  const callApi = async () => {
    const { value } = await props.getMe();
    if (value) {
      setUserData(value);
    }
  };

  const pageEventChange = async (value) => {
    if (value === "next") {
      let pageNum = parseInt(page + 1);
      setEventPage(pageNum);
      const { value } = await props.getEvents({
        count: 100,
        page: 1,
      });
      if (value) {
        setLoader(false);
        let nextPageCount = parseInt(Math.ceil(value?.TotalCount / 100));
        setEventNextStep(nextPageCount);
        setEventsData(value?.Data);
        setTotalEventCount(value?.TotalCount);
      }
    } else if (value === "previous") {
      let pageNum = page - 1 === 0 ? 1 : page - 1;
      setEventPage(pageNum);
      const { value } = await props.getEvents({
        count: 100,
        page: 1,
      });
      if (value) {
        setLoader(false);
        let nextPageCount = parseInt(Math.ceil(value?.TotalCount / 100));
        setEventNextStep(nextPageCount);
        setEventsData(value?.Data);
        setTotalEventCount(value?.TotalCount);
      }
    }
  };

  const pageChange = async (value) => {
    if (value === "next") {
      let pageNum = parseInt(page + 1);
      setPage(pageNum);
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      // save the cancel token for the current request
      cancelToken = axios.CancelToken.source();
      const { value } = await props.getAppUsers({
        count: 100,
        page: pageNum,
        query,
        token: cancelToken.token,
      });
      if (value) {
        setLoader(false);
        // let data = appUsersData.concat(value?.Data);
        setAppUsersData(value?.Data);
        setTotalAppUserCount(value?.TotalCount);
      }
    } else if (value === "previous") {
      let pageNum = page - 1 === 0 ? 1 : page - 1;
      setPage(pageNum);
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      // save the cancel token for the current request
      cancelToken = axios.CancelToken.source();
      const { value } = await props.getAppUsers({
        count: 100,
        page: pageNum,
        query,
        token: cancelToken.token,
      });
      if (value) {
        setLoader(false);
        // let data = appUsersData.concat(value?.Data);
        setAppUsersData(value?.Data);
        setTotalAppUserCount(value?.TotalCount);
      }
    }
  };

  useEffect(() => {
    if (location.pathname === "/app-users") {
      setLoader(true);
      changeTab("app");
    } else if (location.pathname === "/events") {
      setLoader(false);
      changeTab("events");
    }
    if (!isCalled) {
      setIsCalled(true);
      callApi();
    }
  }, []);

  const handleChange = async (q) => {
    setLoader(true);
    setQuery(q);
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    // save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    const { value } = await props.getAppUsers({
      count: 100,
      page: page,
      query: q,
      token: cancelToken.token,
    });
    if (value) {
      // let data = appUsersData.concat(value?.Data);
      setAppUsersData(value?.Data);
      setTotalAppUserCount(value?.TotalCount);
      let time = setInterval(() => {
        setLoader(false);
        clearInterval(time);
      }, 1000);
    } else {
      // setLoader(false);
    }
  };

  const optimizedFn = useCallback(debounce(handleChange), []);
  return (
    <>
      <main className="main" role="header">
        <div className="main__header">
          <TopNavigation userData={userData} {...props} />
        </div>
        <div className="tabs__links tabs__links--space">
          <button
            className={tab === "events" ? "tabs__link active" : "tabs__link"}
            onClick={() => changeTab("events")}
          >
            Events
          </button>
          <button
            className={tab === "app" ? "tabs__link active" : "tabs__link"}
            onClick={() => changeTab("app")}
          >
            App Users
          </button>
        </div>
        <div className="page__header">
          <div className="page__header--container">
            <div className="page__header--row">
              <div className="page__header--left">
                {tab === "events" && (
                  <h1 className="page__header--title">Events</h1>
                )}
                {tab === "app" && (
                  <h1 className="page__header--title">App Users</h1>
                )}
              </div>
              <div className="page__header--right">
                {tab === "app" && (
                  <button
                    onClick={() => {
                      navigate("/app-user");
                    }}
                    type="button"
                    className="btn btn__purple"
                  >
                    <Plus className="btn__icon" /> Add App User
                  </button>
                )}
                {tab === "events" && (
                  <button
                    onClick={() => {
                      navigate("/add-event/general");
                    }}
                    type="button"
                    className="btn btn__purple"
                  >
                    <Plus className="btn__icon" /> Add Event
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {tab === "events" && (
          <div className="custom__container">
            <div className="table-responsive table__responsive--custom">
              <table className="table table__custom">
                <thead>
                  <tr>
                    {/* <th className="table__selection--column">
                    <label className="custom__checkbox--only">
                      <input
                        type="checkbox"
                        className="custom__checkbox--only-input"
                      />
                      <span className="custom__checkbox--only-check"></span>
                    </label>
                  </th> */}
                    {tab === "events" && <th>Event Name</th>}
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <Spin
                      size="large"
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 30, color: "#000" }}
                          spin
                        />
                      }
                      className="loader__full"
                    />
                  ) : (
                    eventsData?.length > 0 &&
                    eventsData?.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td
                            onClick={() =>
                              navigate(`/event-details/general/${e?.Id}`, {
                                state: { from: "events" },
                              })
                            }
                            className="underline"
                          >
                            {e?.Name}{" "}
                          </td>
                          {e?.VisibleInApp ? (
                            <td className="table__status active"> Visible </td>
                          ) : (
                            <td className="table__status inactive">
                              Not Visible
                            </td>
                          )}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            {eventsData.length === 0 && (
              <div className="table__blank">
                <div className="table__blank--text">No Events</div>
              </div>
            )}

            {eventsData?.length > 0 && (
              <div className="table__pagination">
                <div className="table__pagination--results">
                  {totalEventCount} results
                </div>
                <div className="table__pagination--nav">
                  <>
                    {eventPage - 1 >= 1 ? (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--prev"
                        onClick={() => pageEventChange("previous")}
                      >
                        Previous
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--prev disabled"
                      >
                        Previous
                      </button>
                    )}
                  </>
                  <>
                    {eventNextStep - eventPage > 0 ? (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--next"
                        onClick={() => pageEventChange("next")}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--next disabled"
                      >
                        Next
                      </button>
                    )}
                  </>
                </div>
              </div>
            )}
          </div>
        )}
        {tab === "app" && (
          <div className="custom__container">
            <div className="form-group">
              <input
                name="query"
                onChange={(e) => {
                  setQuery(e.target.value);
                  optimizedFn(e.target.value);
                }}
                // onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={query}
                placeholder="Search by name, email, or phone number..."
                className="form-control"
              />
            </div>
            <div className="table-responsive table__responsive--custom">
              <table className="table table__custom">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Events</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>Alex Adams</td>
                    <td>
                      <span className="underline">Alex Adams, </span>{" "}
                      <span className="underline">Alex Adams</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Alex Jane</td>
                    <td>
                      <span className="underline">Alex Adams</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Deepak Devjani</td>
                    <td>-</td>
                  </tr> */}
                  {loader ? (
                    <Spin
                      size="large"
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 30, color: "#000" }}
                          spin
                        />
                      }
                      className="loader__full"
                    />
                  ) : (
                    appUsersData?.length > 0 &&
                    appUsersData?.map((u, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <span
                              onClick={() =>
                                navigate(`/app-user/${u?.AppUser?.Id}`, {
                                  state: { data: u?.AppUser },
                                })
                              }
                              className="underline"
                            >
                              {u?.AppUser?.FirstName} {u?.AppUser?.LastName}
                            </span>
                          </td>
                          <td>
                            {u?.Events?.length === 0 ||
                            (u?.Events?.length === 1 && u?.Events[0] === null)
                              ? "-"
                              : u?.Events?.map((e, h) => {
                                  if (e) {
                                    return (
                                      <>
                                        <span
                                          onClick={() =>
                                            navigate(
                                              `/event-details/general/${e?.Id}`,
                                              {
                                                state: { from: "appusers" },
                                              }
                                            )
                                          }
                                          className="underline"
                                        >
                                          {e.Name}
                                        </span>
                                        {u?.Events?.length - h - 1 !== 0 && (
                                          <>{", "}</>
                                        )}
                                      </>
                                    );
                                  }
                                })}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            {appUsersData?.length <= 0 && (
              <div className="table__blank">
                <div className="table__blank--text">No users</div>
              </div>
            )}
            {appUsersData?.length > 0 && (
              <div className="table__pagination">
                <div className="table__pagination--results">
                  {totalAppUserCount} results
                </div>
                <div className="table__pagination--nav">
                  <>
                    {page - 1 >= 1 ? (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--prev"
                        onClick={() => pageChange("previous")}
                      >
                        Previous
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--prev disabled"
                      >
                        Previous
                      </button>
                    )}
                  </>
                  <>
                    {nextStep - page > 0 ? (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--next"
                        onClick={() => pageChange("next")}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--next disabled"
                      >
                        Next
                      </button>
                    )}
                  </>
                </div>
              </div>
            )}
          </div>
        )}
      </main>
    </>
  );
}
