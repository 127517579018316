import React from "react";
import Dropzone from "react-dropzone";
import ReactModal from "react-modal";
import { Close, Delete, Plus, Remove } from "../../../components/svgIcons";
import ActionMore from "../../../components/common/ActionMore/component";

export default function SkiResultsComponent(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="page__header">
        <div className="page__header--container">
          <div className="page__header--row align-items-center">
            <div className="page__header--left">
              <label className="custom__checkbox">
                <input
                  name="ShowSkiResultsInApp"
                  onChange={(e) => props?.updateEventSettingData(e)}
                  checked={props?.eventSettingData?.ShowSkiResultsInApp}
                  type="checkbox"
                  className="custom__checkbox--input"
                />
                <span className="custom__checkbox--check"></span>
                Show section tab in Mobile App
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="custom__container">
        <div className="custom__wrap">
          <div className="form-group">
            <label className="label-primary">ski Results PDF</label>
            {/* <Dropzone
              accept="image/*"
              multiple={true}
              disabled={this.state.previewArr.length >= 50}
              onDrop={acceptedFiles =>
                this.addImages(acceptedFiles, values.images, setFieldValue)
              }
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone">
                  <div className="dropzone__area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <button type="button" className="btn btn__green">
                      {this.state.loader ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <>
                          <Plus className="btn__icon" /> Upload Photo
                        </>
                      )}
                    </button>
                  </div>
                </section>
              )}
            </Dropzone> */}
            <>
              <div className="invalid-feedback">
                Please upload an image that in 10mb or smaller. You can use the
                following compressors to make shrink your images:
              </div>
            </>
            <div className="invalid-feedback">Maximum 50 photos</div>
            <div className="dropzone__grid">
              <div className="dropzone__grid--item">
                <div className="dropzone__grid--preview">
                  <img
                    className="preview__thumb"
                    src="https://images.unsplash.com/photo-1662581871665-f299ba8ace07?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80"
                  />
                  <div className="dropzone__grid--remove">
                    <button className="btn btn__remove">
                      <Remove />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
