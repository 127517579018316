import { connect } from "react-redux";
import { getMe, editProfile, getUserDetails } from "../../store/home/duck";
import AdminAccountComponent from "./component";

const AdminAccountComponentContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    getMe,
    editProfile,
    getUserDetails,
  }
)(AdminAccountComponent);

export default AdminAccountComponentContainer;
