import { connect } from "react-redux";
import { getMe, addProfile, getUserDetails } from "../../store/home/duck";
import AddAccountComponent from "./component";

const AddAccountComponentContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    getMe,
    addProfile,
    getUserDetails,
  }
)(AddAccountComponent);

export default AddAccountComponentContainer;
