import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Close, Plus } from "../../components/svgIcons";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { size } from "lodash";

export default function UserComponent(props) {
  const navigate = useNavigate();
//   const location = useLocation();
  const [page, setPage] = useState(1);
  const [nextStep, setNextStep] = useState(0);
  const [loader, setLoader] = useState(true);
  const [isCalled, setIsCallApi] = useState(false);
  const [stateData, setStateData] = useState("");
  const [dataList, setDataList] = useState({});
  const [searchText, setSearchText] = useState("");

  const callApi = async (name="") => {
    setLoader(true)
    const { value } = await props.GetUsers({  page: 1, count: 100, searchText: name });
    if(value?.Success){
      if(value.Result){
        setDataList(value.Result);
        setNextStep(value.TotalCount)
        setLoader(false);
      }else{
        setDataList([]);
        setNextStep(0)
        setLoader(false);
      } 
    }else{
      setDataList([]);
      setNextStep(0)
      setLoader(false);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const pageChange = async (value) => {
    setLoader(true);
    if (value === "next") {
      let pageNum = page + 1;
      setPage(pageNum);
      const { value } = await props.GetUsers({  page: pageNum, count: 100, searchText: searchText });
      setDataList(value.Result);
    } else if (value === "previous") {
      let pageNum = page - 1 === 0 ? 1 : page - 1;
      setPage(pageNum);
      const { value } = await props.GetUsers({  page: pageNum, count: 100, searchText: searchText });
      setDataList(value.Result);
    }
    setLoader(false);
  }

  useEffect(() => {
    if (!isCalled) {
      callApi();
      setIsCallApi(true);
    }
  }, [dataList, stateData]);

  const optimizedUserFn = useCallback(debounce(callApi), []);

  return (
    <>
      <main class="main home__thmes-wrapper" role="header">
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate("/")}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">
                    Content Hub Users
                  </div>
                </div>
                <div className="">
                  <button
                    type="button"
                    onClick={() => navigate("/add-user-profile")}
                    className="btn btn__purple"
                    >
                    <Plus className="btn__icon" /> Add New App User
                  </button>
                </div>
              </div>
            </div>
          </header>
        </div>
        
        <div className="custom__container">
        <div className="form-group mt-2">
              <input
                placeholder="Search by first name or last name..."
                className="form-control"
                value={searchText}
                type="text"
                name="searchText"
                onChange={(e)=>{
                  // console.log(e.target.value)
                  setSearchText(e.target.value)
                  optimizedUserFn(e.target.value)
                }}
              />
            </div>
          <div className="table-responsive table__responsive--custom">
            <table className="table table__custom table-fixed">
              <thead>
                <tr>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <Spin
                    size="large"
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 30, color: "#000" }}
                        spin
                      />
                    }
                    className="loader__full"
                  />
                ) : (
                  dataList?.length > 0 &&
                  dataList?.map((news, i) => {
                    if(news.FirstName){
                      return (
                        <tr key={i}>
                          <td
                              onClick={() =>navigate(`/edit-user-profile/${news.AplicationId}`)                         
                              }
                              className="underline cursor"
                          >{news.FirstName} {news.lastName}</td>
                        </tr>
                      );
                    }
                  })
                )}
              </tbody>
            </table>
          </div>
          {(dataList?.length === 0 && !loader) &&<div className="table__blank">
            <div className="table__blank--text">No Data</div>
          </div> }
          {dataList?.length > 0 && (
            <div className="table__pagination">
              <div className="table__pagination--results">
                {size(dataList)} results
              </div>
              <div className="table__pagination--nav">
                <>
                  {page - 1 >= 1 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--prev"
                      onClick={() => pageChange("previous")}
                    >
                      Previous
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled
                      className="btn btn__default table__pagination--prev disabled"
                    >
                      Previous
                    </button>
                  )}
                </>
                <>
                  {nextStep - page > 0 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--next"
                      onClick={() => pageChange("next")}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled
                      className="btn btn__default table__pagination--next disabled"
                    >
                      Next
                    </button>
                  )}
                </>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
}
