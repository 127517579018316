import { connect } from "react-redux";
import { verifyOtp } from "../../store/home/duck";
import verificationCode from "./component";

const VerificationCodeContainer = connect(
  // Map state to props
  (state) => ({
    otpPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    verifyOtp,
  }
)(verificationCode);

export default VerificationCodeContainer;
