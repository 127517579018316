import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="25"
      fill="none"
      viewBox="0 0 33 25"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.998 2.201l-.831 1.57H3.556A3.556 3.556 0 000 7.325v13.325a3.556 3.556 0 003.556 3.556h25.888A3.556 3.556 0 0033 20.65V7.326a3.556 3.556 0 00-3.556-3.556h-5.61l-.832-1.569A3.556 3.556 0 0019.86.311h-6.72A3.556 3.556 0 009.998 2.2zm.233 3.358l1.332-2.515a1.778 1.778 0 011.572-.945h6.72c.658 0 1.262.364 1.57.945l1.333 2.515h6.68c.982 0 1.778.796 1.778 1.778v13.324c0 .982-.796 1.778-1.778 1.778H3.55a1.778 1.778 0 01-1.778-1.778V7.337c0-.982.797-1.778 1.778-1.778h6.681zm6.153 13.443c-3.26 0-5.91-2.597-5.91-5.809s2.65-5.809 5.91-5.809c3.26 0 5.911 2.597 5.911 5.809s-2.65 5.809-5.91 5.809zm4.16-5.815c0 2.223-1.846 4.031-4.133 4.031-2.286 0-4.133-1.809-4.133-4.03 0-2.223 1.847-4.031 4.133-4.031 2.287 0 4.133 1.808 4.133 4.03z"
        clipRule="evenodd"
      ></path>
      <rect
        width="4.552"
        height="2.276"
        x="4.544"
        y="7.416"
        fill="#fff"
        rx="1"
      ></rect>
    </svg>
  );
}

export default Icon;
