import { connect } from "react-redux";
// import { appUserDelete } from "../../store/home/duck";
import AddEditAgendaComponent from "./component";

const AddEditEventContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {}
)(AddEditAgendaComponent);

export default AddEditEventContainer;
