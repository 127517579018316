import { connect } from "react-redux";
import { getThemeAndOtherNewByName, updateTags, GetCategoryCount, getLtWeeklyThemeCategories,updateTagsOtherNewsandThemes,RemoveTagsOtherNewsandThemes } from "../../store/home/duck";
import TagsComponent from "./component";

const TagsContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    updateTags,
    getLtWeeklyThemeCategories,
    GetCategoryCount,
    getThemeAndOtherNewByName,
    updateTagsOtherNewsandThemes,
    RemoveTagsOtherNewsandThemes
  }
)(TagsComponent);

export default TagsContainer;
