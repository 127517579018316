import * as React from "react"

const Setting = ({ ...props }) => {
  return (
    <svg
      width={14}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.197 6.517c-.23 0-1.607 0-2.18-.695 0-.115-.115-.115-.115-.231-.115-.81.918-1.851 1.032-1.967.23-.231.23-.579.115-.694-.115-.116-.574-.579-.688-.81-.115-.232-.46-.116-.689.116-.23.23-1.147 1.157-1.95 1.04-.115 0-.115-.115-.23-.115-.689-.463-.689-1.851-.689-2.082C7.803.731 7.689.5 7.459.5H6.311c-.229 0-.344.231-.344.579 0 .23 0 1.62-.688 2.198-.115 0-.115.116-.23.116-.803 0-1.721-.926-1.95-1.157-.23-.232-.574-.232-.69-.116-.114.231-.573.694-.802.81-.115.115-.115.463.114.694.23.231 1.148 1.157 1.033 1.967 0 .116-.115.116-.115.231-.459.695-1.836.695-2.065.695-.344 0-.574.115-.574.347v1.041c0 .231.23.463.574.463.23 0 1.606 0 2.18.694 0 .116.115.116.115.231.115.81-.918 1.852-1.033 1.967-.23.232-.23.579-.115.695.115.115.574.578.689.81.115.115.459.115.688-.116.23-.232 1.148-1.157 1.951-1.042.115 0 .115.116.23.116.688.579.688 1.851.688 2.198 0 .232.23.579.46.579h1.032c.23 0 .459-.231.459-.579 0-.23 0-1.62.689-2.198.114 0 .114-.116.23-.116.802-.115 1.835.926 1.95 1.042.23.231.574.231.688.115l.804-.81c.114-.115.114-.462-.115-.694-.23-.231-1.148-1.157-1.033-1.967 0-.115.115-.115.115-.231.574-.694 1.836-.694 2.18-.694.23 0 .574-.232.574-.463V6.864c-.23-.232-.459-.347-.803-.347Zm-6.312 3.47c-1.377 0-2.524-1.156-2.524-2.545 0-1.388 1.147-2.545 2.524-2.545S9.41 6.054 9.41 7.442c0 1.389-1.148 2.546-2.525 2.546v-.001Z"
        fill="#4F566B"
      />
    </svg>
  );
};



export default Setting
