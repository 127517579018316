import ReactModal from "react-modal";

import React from "react";
import { Close, Delete, Plus } from "../../components/svgIcons";
import Logo from "../../components/svgIcons/Logo";
import ProfileComponent from "../Account/Profile/component";
import { useNavigate } from "react-router-dom";

export default function AddEditAgendaComponent() {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  
  const navigate = useNavigate();
  return (
    <>
      <main className="main" role="tabs">
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate("/bulk-tag")}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">Event Item</div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="custom__container">
          <div className="custom__wrap">
            <div className="form-group">
              <label className="label-primary">Type (optional)</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="label-primary">
                Title <span className="required">*</span>
              </label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="label-primary">Description (optional)</label>
              <textarea type="text" className="form-control" />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-primary">
                    Date <span className="required">*</span>
                  </label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-primary">
                    Start Date <span className="required">*</span>
                  </label>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="label-primary">
                Location Name <span className="required">*</span>
              </label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="label-primary">
                Location Address <span className="required">*</span>
              </label>
              <input type="text" className="form-control" />
            </div>

            <div className="speakers-form-container">
              <div className="form-group mb-small-15">
                <label className="label-primary">Speakers</label>
              </div>
              <div className="speakers--row">
                <div className="speakers--column">
                  <div className="form-group">
                    <div className="inline-remove-field">
                      <label className="label-primary label-title">
                        Alex Jane
                      </label>
                      <button className="btn btn__delete--icon">
                        <Delete />
                      </button>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="label-primary">
                      Speaker Type (optional)
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="speakers--column">
                  <div className="form-group">
                    <div className="inline-remove-field">
                      <label className="label-primary label-title">
                        Deepak Devjani
                      </label>
                      <button className="btn btn__delete--icon">
                        <Delete />
                      </button>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="label-primary">
                      Speaker Type (optional)
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button className="btn btn__purple" onClick={openModal}>
                  <Plus className="btn__icon" /> Add Speaker
                </button>
              </div>
            </div>

            {/* <div className="form-group">
              <ul className="form-list-item">
                <li>
                  <span>Alex Jane</span>
                  <label className="custom__checkbox--only">
                    <input
                      type="checkbox"
                      className="custom__checkbox--only-input"
                    />
                    <span className="custom__checkbox--only-check"></span>
                  </label>
                </li>
                <li>
                  <span>Alex Jane</span>
                  <label className="custom__checkbox--only">
                    <input
                      type="checkbox"
                      className="custom__checkbox--only-input"
                    />
                    <span className="custom__checkbox--only-check"></span>
                  </label>
                </li>
                <li>
                  <span>Alex Jane</span>
                  <label className="custom__checkbox--only">
                    <input
                      type="checkbox"
                      className="custom__checkbox--only-input"
                    />
                    <span className="custom__checkbox--only-check"></span>
                  </label>
                </li>
                <li>
                  <span>Alex Jane</span>
                  <label className="custom__checkbox--only">
                    <input
                      type="checkbox"
                      className="custom__checkbox--only-input"
                    />
                    <span className="custom__checkbox--only-check"></span>
                  </label>
                </li>
              </ul>
            </div> */}

            <div className="stack-button-container">
              <button className="btn btn-lg btn__purple w-100">Save</button>
              <button className="btn btn__orange">Delete Agenda Item</button>
            </div>
          </div>
        </div>
      </main>

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header border-bottom">
            <h5 className="react-modal-title">App Users</h5>
            <button className="btn react-modal-close" onClick={closeModal}>
              <Close />
            </button>
          </div>
          <div className="react-modal-body react-modal-body-style-2">
            <div className="form-group">
              <input
                type="text"
                name=""
                placeholder="Search by name, email, or phone number..."
                className="form-control"
                required
              />
              <div className="invalid-feedback">Error</div>
            </div>
            <div className="margin-minus-15">
              <div className="modal-list-container">
                <div className="modal-list-items">
                  <label className="custom__checkbox">
                    <input
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Alex Jane
                  </label>
                </div>
                <div className="modal-list-items">
                  <label className="custom__checkbox">
                    <input
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Deepak Devjani
                  </label>
                </div>
                <div className="modal-list-items">
                  <label className="custom__checkbox">
                    <input
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Vir Chan
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="react-modal-footer">
            <button type="submit" className="btn btn-lg btn__purple w-100">
              Save & Update
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
