import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// import Styles from "./profile.module.css"
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
export default function ProfileComponent(props) {
  const [data, setData] = useState({});
  const handleChange = (name, value) => {
    data[name] = value;
    setData({ ...data });
  };
  const [isLoading, setIsloading] = useState(false);
  const [isCalled, setIsCalled] = useState(false);

  const callGetUserDetails = async(id) => {
    const { value } = await props.getUserDetails(parseInt(id));
    setData(value);
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Liontree",
      description: message,
    });
  };

  const callApi = async () => {
    const { value } = await props.getMe();
    if (value) {
      callGetUserDetails(value?.Id)
    }
  };

  useEffect(() => {
    if (!isCalled) {
      setIsCalled(true);
      callApi();
    }
  }, []);

  const updateUser = async () => {
    if (!get(data, "FirstName", "")) {
    } else if (!get(data, "SSOEmail", "")) {
    } else if (!get(data, "lastName", "")) {
    } else {
      setIsloading(true);
      if(data?.IIMWeeklies === null){
        data.IIMWeeklies = 0
      }
      if(data?.CapitalWeeklies === null){
        data.CapitalWeeklies = 0
      }
      let { value } = await props.editProfile(data);
      if (value) {
        setIsloading(false);
        openNotificationWithIcon("success", value);
      }
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="label-primary">
              First Name<span className="required">*</span>
            </label>
            <input
              type="text"
              name="FirstName"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={data?.FirstName}
              className="form-control"
              required
            />
            {data?.FirstName === "" && (
              <div className="invalid-feedback">Enter your first name</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="label-primary">
              Last Name<span className="required">*</span>
            </label>
            <input
              type="text"
              name="lastName"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={data?.lastName}
              className="form-control"
              required
            />
            {data?.lastName === "" && (
              <div className="invalid-feedback">Enter your last name</div>
            )}{" "}
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="label-primary">
          Email Address<span className="required">*</span>
        </label>
        <input
          type="text"
          value={data?.SSOEmail}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          name="SSOEmail"
          className="form-control"
          required
        />
        {data?.SSOEmail === "" && (
          <div className="invalid-feedback">Enter your email</div>
        )}{" "}
      </div>

      <button
        type="submit"
        className="btn btn-lg btn__purple w-100"
        onClick={() => updateUser()}
      >
        {isLoading ? <Spin indicator={antIcon} /> : "Save"}
      </button>
    </>
  );
}
