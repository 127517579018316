import React from "react";
import { HolderOutlined } from "@ant-design/icons";
import ReactModal from "react-modal";
import { Plus, Setting } from "../../../components/svgIcons";
import ActionMore from "../../../components/common/ActionMore/component";

export default function EventMapComponent(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="page__header">
        <div className="page__header--container">
          <div className="page__header--row align-items-center">
            <div className="page__header--left">
              <label className="custom__checkbox">
                <input
                  name="ShowMapDetailsInApp"
                  onChange={(e) => props?.updateEventSettingData(e)}
                  checked={props?.eventSettingData?.ShowMapDetailsInApp}
                  type="checkbox"
                  className="custom__checkbox--input"
                />
                <span className="custom__checkbox--check"></span>
                Show section tab in Mobile App
              </label>
            </div>
            <div className="page__header--right">
              {/* <button type="button" className="btn btn__default">
                <Setting className="btn__icon" /> Add New User to Event 
              </button> */}
              <button
                type="button"
                className="btn btn__purple"
                onClick={openModal}
              >
                <Plus className="btn__icon" /> Add Event Location
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="custom__container">
        <div className="table-responsive table__responsive--custom">
          <table className="table table__custom items-order-table">
            <thead>
              <tr>
                <th>Location Name</th>
                <th>Location Adddress</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="underline">
                  <HolderOutlined className="reorderIcon" />
                  St Regis
                </td>
                <td>2300 Deer Valley Drive East, Park City, UT 84060</td>
                <td className="text-end">
                  <ActionMore />
                </td>
              </tr>
              <tr>
                <td className="underline">
                  <HolderOutlined className="reorderIcon" />
                  Goldener Hirsh
                </td>
                <td>7570 Royal St, Park City, UT 84060</td>
                <td className="text-end">
                  <ActionMore />
                </td>
              </tr>
              <tr>
                <td className="underline">
                  <HolderOutlined className="reorderIcon" />
                  Montage
                </td>
                <td>7000 Park Ave St, Park City, UT 84060</td>
                <td className="text-end">
                  <ActionMore />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <div className="table__blank">
          <div className="table__blank--text">No users</div>
        </div> */}

        <div className="table__pagination">
          <div className="table__pagination--results">3 results</div>
          <div className="table__pagination--nav">
            <button
              type="button"
              className="btn btn__default table__pagination--prev disabled"
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn__default table__pagination--next disabled"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Event Location</h5>
            {/* <button className="btn react-modal-close" onClick={closeModal}><Close /></button> */}
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">Loaction Title</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="label-primary">Loaction Adddress</label>
              <input type="text" className="form-control" />
            </div>
          </div>
          <div className="react-modal-footer">
            <button className="btn btn__default" onClick={closeModal}>
              Cancel
            </button>
            <button type="submit" className="btn btn__purple">
              Update
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
