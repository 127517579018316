import { connect } from "react-redux";
import AdminComponent from "./component";
import {
  getAgoloNewsByDate,
  updateTags,
  getLtWeeklyThemeCategories,
  removeAgoloNews,
  updateArticles,
  getCuratedTags,
  SendEmailToLtTeam,
  getArticleFromUrl,
  addCuratedTags,
  getAllCreatedAgoloNewsDate,
  getAllCreatedDraftAgoLoNewsDate
} from "../../store/home/duck";

const AdminComponentContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    SendEmailToLtTeam,
    updateArticles,
    getArticleFromUrl,
    getAgoloNewsByDate,
    updateTags,
    getLtWeeklyThemeCategories,
    removeAgoloNews,
    getCuratedTags,
    addCuratedTags,
    getAllCreatedAgoloNewsDate,
    getAllCreatedDraftAgoLoNewsDate
  }
)(AdminComponent);

export default AdminComponentContainer;
