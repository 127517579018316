import { connect } from "react-redux";
import {
  updateTags,
  getLtWeeklyThemeCategories,
  removeAgoloNews,
  getAgoloNewsByDate,
  getCuratedTags,
  addCuratedTags,
  getLtWeeklyItems,
  UpdateLTWeeklyOthersNews,
  updateArticles,
  // SyncOtherNews,
  getMe,
  getUserDetails,
  getAllCreatedAgoloNewsDate,
  AddNewLTWeeklyEntry
} from "../../store/home/duck";
import AdminComponent from "./component";

const AdminComponentContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    getUserDetails,
    getMe,
    // SyncOtherNews,
    UpdateLTWeeklyOthersNews,
    updateArticles,
    updateTags,
    getLtWeeklyItems,
    getLtWeeklyThemeCategories,
    removeAgoloNews,
    getCuratedTags,
    addCuratedTags,
    getAgoloNewsByDate,
    getAllCreatedAgoloNewsDate,
    AddNewLTWeeklyEntry
  }
)(AdminComponent);

export default AdminComponentContainer;
