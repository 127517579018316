import { connect } from "react-redux";
import AddLtWeeklyComponent from "./component";
import {getMailingList,  AddLTWeekly, removeThemeById, uploadImage } from "../../store/home/duck";
const AddLtWeeklyContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
  	getMailingList,
  	AddLTWeekly,
    uploadImage
  }
)(AddLtWeeklyComponent);

export default AddLtWeeklyContainer;
