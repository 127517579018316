import { connect } from "react-redux";
import { getOtp, VerifyEmail, resetLoginPhase } from "../../store/home/duck";
import login from "./component";

const LoginContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
  	VerifyEmail,
    resetLoginPhase,
    getOtp,
  }
)(login);

export default LoginContainer;
