import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.scss";

// components
import Show from "../views/Show/container";
import Login from "../views/Login/container";
import VerificationCode from "../views/VerificationCode/container";
import Account from "../views/Account/container";
import ShowDetails from "../views/ShowDetails/container";
import AppUsers from "../views/AppUser/component";
import AddEditAgenda from "../views/AddEditAgenda/container";
import HomeThmes from "../views/HomeThemes/container";
import ViewOtherNews from "../views/ViewOtherNews/container";
import AddEditAppUserComponent from "../views/AddEditAppUser/container";
import MailListDetail from "../views/MailListDetail/container";
import AddLtWeekly from "../views/AddLtWeekly/container";
import { Navigate } from "react-router-dom";
import AddNewsArticles from "../views/AddNewsArticles/container";
import EditNewsArticles from "../views/EditNewsArticles/container";
import CampaignDetails from "../views/CampaignDetails/container";
import TagDetails from "../views/TagDetails/container";
import Users from "../views/Users/container";
import EditUserProfile from "../views/EditUserProfile/container";
import AddUserProfile from "../views/AddUserProfile/container";
import UploadAnalytics from "../views/UploadAnalytics/container";
import SendEmailLtWeekly from "../views/SendEmailLtWeekly/container";
import { MsalProvider } from "@azure/msal-react";
const PrivateRoute = ({ children }) => {
  return localStorage.getItem("Authorization") ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

function App(props) {
  return (
    <Router>
      <MsalProvider instance={props?.pca}>
      <Routes>
        <Route exact path="/" element={<Navigate replace to="/login" />} />
        {/* <Route exact path="/" element={<Login {...props} />} /> */}
        <Route
          exact
          path="/verify-code"
          element={<VerificationCode {...props} />}
        />
        {/* <Route exact path="/shows" element={<Onborading />} /> */}
        <Route exact path="/login" element={<Login {...props} />} />
        <Route
          exact
          path="/events"
          element={
            <PrivateRoute>
              <Show {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/app-users"
          element={
            <PrivateRoute>
              <Show {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/app-user/:id"
          element={
            <PrivateRoute>
              <AddEditAppUserComponent {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/app-user"
          element={
            <PrivateRoute>
              <AddEditAppUserComponent {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/account/profile"
          element={
            <PrivateRoute>
              <Account {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/event-details/:Id"
          element={
            <PrivateRoute>
              <ShowDetails {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/add-event/general"
          element={
            <PrivateRoute>
              <ShowDetails {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/event-details/general/:Id"
          element={
            <PrivateRoute>
              <ShowDetails {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/event-details/agenda/:Id"
          element={
            <PrivateRoute>
              <ShowDetails {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/event-details/attendees/:Id"
          element={
            <PrivateRoute>
              <ShowDetails {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/event-details/activities/:Id"
          element={
            <PrivateRoute>
              <ShowDetails {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/event-details/ski-results/:Id"
          element={
            <PrivateRoute>
              <ShowDetails {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/event-details/photo-video/:Id"
          element={
            <PrivateRoute>
              <ShowDetails {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/event-details/event-map/:Id"
          element={
            <PrivateRoute>
              <ShowDetails {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/event-details/quiz/:Id"
          element={
            <PrivateRoute>
              <ShowDetails {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/app-users"
          element={
            <PrivateRoute>
              <AppUsers {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/add-edit-agenda/:Id"
          element={
            <PrivateRoute>
              <AddEditAgenda {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/add-edit-agenda"
          element={
            <PrivateRoute>
              <AddEditAgenda {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/bulk-tag"
          element={
            <PrivateRoute>
              <HomeThmes {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
            path="/iim-bulk-tags"
            element={
              <PrivateRoute>
                <HomeThmes {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
          path="/capital-markets-analytics"
          element={
            <PrivateRoute>
              <HomeThmes {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
            path="/administration/users"
            element={
              <PrivateRoute>
                <Users {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-user-profile/:id"
            element={
              <PrivateRoute>
                <EditUserProfile {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/add-user-profile"
            element={
              <PrivateRoute>
                <AddUserProfile {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
          path="/iim-analytics"
          element={
            <PrivateRoute>
              <HomeThmes {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/capital-markets"
          element={
            <PrivateRoute>
              <HomeThmes {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/iim-weeklies"
          element={
            <PrivateRoute>
              <HomeThmes {...props} />
            </PrivateRoute>
          }
        />
          <Route
            exact
            path="/iim-tags"
            element={
              <PrivateRoute>
                <HomeThmes {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/iim-tags/:name"
            element={
              <PrivateRoute>
                <TagDetails {...props} />
              </PrivateRoute>
            }
          />
        <Route
          exact
            path="/iim-tags/:name/all"
            element={
              <PrivateRoute>
                <TagDetails {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/iim-tags/:name/themes"
            element={
              <PrivateRoute>
                <TagDetails {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/iim-tags/:name/other-news"
            element={
              <PrivateRoute>
                <TagDetails {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/themes"
            element={
              <PrivateRoute>
                <ViewOtherNews {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/mailing-list-detail"
            element={
              <PrivateRoute>
                <MailListDetail {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/themes/:ltWeeklyDate"
            element={
              <PrivateRoute>
                <ViewOtherNews {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/other-news/:ltWeeklyDate"
            element={
              <PrivateRoute>
                <ViewOtherNews {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/poll/:ltWeeklyDate"
            element={
              <PrivateRoute>
                <ViewOtherNews {...props} />
              </PrivateRoute>
            }
          />
            <Route
            exact
            path="/poll-results/:ltWeeklyDate"
            element={
              <PrivateRoute>
                <ViewOtherNews {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/mailing-list-detail/:id"
            element={
              <PrivateRoute>
                <MailListDetail {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/campaigns"
            element={
              <PrivateRoute>
                <HomeThmes {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/campaign/:id"
            element={
              <PrivateRoute>
                <CampaignDetails {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/campaign/overview/:id"
            element={
              <PrivateRoute>
                <CampaignDetails {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/campaign/search/:id"
            element={
              <PrivateRoute>
                <CampaignDetails {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/mailing-list"
            element={
              <PrivateRoute>
                <HomeThmes {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/free-form-emails"
            element={
              <PrivateRoute>
                <HomeThmes {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/add-news-articles/:ltWeeklyDate"
            element={
              <PrivateRoute>
                <AddNewsArticles {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-news-articles/:ltWeeklyDate"
            element={
              <PrivateRoute>
                <EditNewsArticles {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/add-ltweekly"
            element={
              <PrivateRoute>
                <AddLtWeekly {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/send-email"
            element={
              <PrivateRoute>
                <SendEmailLtWeekly {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/send-email/:id"
            element={
              <PrivateRoute>
                <SendEmailLtWeekly {...props} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/upload-analytics"
            element={
              <PrivateRoute>
                <UploadAnalytics {...props} />
              </PrivateRoute>
            }
          />
        </Routes>
      </MsalProvider>
    </Router>
  );
}

export default App;
