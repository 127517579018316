import * as React from "react";

const RightArrow = ({ ...props }) => {
  return (
    <svg
      width={19}
      height={16}
      fill={props.disabled ? "#ccc" : "#fff"}
      xmlns="http://www.w3.org/2000/svg"
      style={{ pointerEvents: props.disabled ? "none" : "auto" }}
      {...props}
    >
      <path
        d="M17.727 8.707a1 1 0 0 0 0-1.414L11.363.929A1 1 0 1 0 9.95 2.343L15.606 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h17.02V7H0v2Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default RightArrow;
