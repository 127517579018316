import fetch from "isomorphic-fetch";
// import Cookies from 'universal-cookie'

// import Config from '../config'

// const cookies = new Cookies()



const handleHTTPErrors = (res) => {
  console.log("Response:", res);

  if (res && res.status === 401) {
      localStorage.removeItem('Authorization')
      window.location.href = "/login";
    // Throw an error to prevent further promise chain execution
    // throw new Error("Unauthorized");
  }

  if (res && res.ok) return res;

  return res.json().then((err) => {
    throw err;
  });
};
export default function fetchUrl(url, options) {
  const jwtToken = localStorage.getItem("Authorization");
  // const jwtToken = cookies.get('Authorization');
  if (jwtToken || true) {
    let authAddedOptions = options;
    if (typeof options !== "object") {
      authAddedOptions = {};
    }
    if (typeof authAddedOptions.headers !== "object") {
      authAddedOptions.headers = {};
    }
    authAddedOptions.headers = {
      ...authAddedOptions.headers,
      Authorization: "Bearer " + jwtToken,
      // Authorization: 
      //   "Bearer " +
      //   "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAidW5pcXVlX25hbWUiOiAic2phaW4iLA0KICAiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9BcHBVc2VySWQiOiAiMTE3OTUiLA0KICAiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9GZWRlcmF0ZWRVc2VySWQiOiAiYjk2NzkzMDgtM2IyNC00M2JkLWJhZmYtNWRjMGZiNDUxMGU1IiwNCiAgImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvTmlja05hbWUiOiAiYjk2NzkzMDgtM2IyNC00M2JkLWJhZmYtNWRjMGZiNDUxMGU1IiwNCiAgIm5iZiI6IDE2NjUxMTkyNTIsDQogICJleHAiOiAxNjY3NzExMjUyLA0KICAiaWF0IjogMTY2NTExOTI1MiwNCiAgImlzcyI6ICJpc3MiDQp9.iqai9KxmQ53v3QNXhRFYYGupBycOh8qD2l5b6eT6Xj4",
    };
    

    return fetch(url, authAddedOptions)
  .then(handleHTTPErrors)
  
  .catch((error) => {
    if (error.message === "Unauthorized") {
      // Handle the 401 Unauthorized error and redirect to the login page
      // localStorage.removeItem('Authorization')
      // window.location.href = "/login";
    } else {
      // Handle other errors
    }
  });
  } else {
    return fetch(url, options).then(handleHTTPErrors);
  }
}

