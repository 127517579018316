import React, { useState, useEffect } from "react";
import Logo from "../../components/svgIcons/Logo";
import { useLocation, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
import S from "./verificationCode.module.css";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function VerificationCodeComponent(props) {
  const [isLoading, setLoading] = useState(false);
  let testInput;
  const [data, setData] = useState({});
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const { state } = location;
    setTimeout(() => {
      testInput && testInput.focus();
    }, 500);
    if (!state?.phoneData) {
      navigate("/login");
    } else {
      setData(state);
    }
  }, []);

  const verifyOtp = async () => {
    if (code) {
      setLoading(true);
      let payload = {
        PhoneNumber: data?.phoneData.cc + data?.phoneData.phone,
        Code: code,
      };
      const { value } = await props.verifyOtp(payload);
      if (value?.IsSuccess) {
        localStorage.setItem("Authorization", value?.DetailMessage);
        navigate("/iim-weeklies");
      } else {
        setLoading(false);
        setErrorMessage(value?.ShortMessage);
      }
    } else {
      setErrorMessage("Please enter the code");
    }
  };

  return (
    <>
      <main className={S.main}>
        <div className={S.wrapper}>
          <div className={S.head}>
            <div className={S.logo}>
              <Logo />
            </div>
            <h1 className={S.title}>Verify it’s you </h1>
            <p className={S.subTitle}>
              We sent you a 6-digit code to mobile number so you can login{" "}
            </p>
            <div className={S.codeSent}>
              code sent to:{" "}
              {formatPhoneNumber(data?.phoneData?.cc + data?.phoneData?.phone)}
            </div>
          </div>
          <div className="form-group">
            <label className="label-primary">Verification code</label>
            <input
              type="number"
              name="code"
              onChange={(e) => {
                setErrorMessage("");
                if (e.target.value?.length <= 6) {
                  setCode(e.target.value);
                }
              }}
              value={code}
              // ref={(input) => {
              //   testInput = input;
              // }}
              className="form-control"
              required
            />
            {errorMessage && (
              <div className="invalid-feedback">{errorMessage}</div>
            )}
          </div>
          <div>
            <button
              onClick={() => verifyOtp()}
              type="submit"
              className="btn btn-lg btn__purple w-100"
            >
              {isLoading ? <Spin indicator={antIcon} /> : "Verify Code"}
            </button>
          </div>
        </div>
      </main>
    </>
  );
}
