import React, { useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import ReactModal from "react-modal";
import CheckboxComponent from "../../../components/common/ui/CheckBox";
import { Close, Delete, Plus, Remove } from "../../../components/svgIcons";
import axios from "axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
let cancelToken;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function GeneralComponent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("show");
  const [eventId, setEventId] = useState(0);
  const [eventAppUsers, setEventAppUsers] = useState([]);
  const [eventData, setEventData] = useState({
    CreatedAt: null,
    Id: 0,
    ImageUrl: "",
    Name: "",
    VisibleInApp: true,
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isCalled, setIsCalled] = useState(false);
  const [data, setData] = useState({});
  const [appUsers, setAppUsersData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [query, setQuery] = useState("");
  const [imageError, setImageError] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const callEventApi = async (id) => {
    const { value } = await props.getEventDetails({ Id: id });
    if (value) {
      setEventData(value?.Data?.Event);
      setEventAppUsers(value?.Data?.AppUsers);
      setSelectedStatus(
        value?.Data?.Event?.VisibleInApp === true ? "show" : "hide"
      );
    }
  };
  const callApi = async () => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    // save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    const { value } = await props.getAppUsers({
      count: 10,
      page: 1,
      query: "",
      token: cancelToken.token,
    });
    if (value) {
      // setLoader(false);
      // let nextPageCount = parseInt(Math.ceil(value?.TotalCount / 100));
      // setNextStep(nextPageCount);
      setAppUsersData(value?.Data);
      setLoader(false);

      // setTotalAppUserCount(value?.TotalCount);
    }
  };
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const callAddUserToEvent = async (data) => {
    const { value } = await props.addAppUserToEvent(data);
    if (value) {
      openNotificationWithIcon(
        "success",
        "Updated event details successfully."
      );
      setIsLoading(false);
    }
  };
  const addEvent = async () => {
    setIsLoading(true);
    if (params?.Id) {
      const { value } = await props.addEvent(eventData);
      if (value?.Result) {
        let eventUserList = [];
        let prm = eventAppUsers.map((e) => {
          let data = {
            EventId: value?.Result?.Id,
            AppUserId: e?.Id,
            ManagerActiveStatus: true,
          };
          eventUserList.push(data);
        });
        await Promise.all(prm);
        callAddUserToEvent(eventUserList);
      }
    } else {
      const { value } = await props.addEvent(eventData);
      if (value?.Result) {
        let eventUserList = [];
        let prm = eventAppUsers.map((e) => {
          let data = {
            EventId: value?.Result?.Id,
            AppUserId: e?.Id,
            ManagerActiveStatus: true,
          };
          eventUserList.push(data);
        });
        await Promise.all(prm);
        callAddUserToEvent(eventUserList);
      }
    }
  };

  useEffect(() => {
    if (!isCalled) {
      setEventId(params?.Id);
      setIsCalled(true);
      if (parseInt(params?.Id)) {
        callEventApi(parseInt(params?.Id));
      }
      callApi();
    }
  }, [isCalled]);
  const activeStatus = [
    { key: "show", value: "Show" },
    { key: "hide", value: "Hide" },
  ];
  useEffect(() => {
    setSelectedUsers(selectedUsers);
  }, [selectedUsers]);

  useEffect(() => {}, [eventData]);
  const handleChange = async (name, q) => {
    if (name === "Name") {
      eventData[name] = q;
      setEventData({ ...eventData });
    } else if (name === "VisibleInApp") {
      setSelectedStatus(q);
      eventData[name] = q === "hide" ? false : true;
      setEventData({ ...eventData });
    } else {
      setLoader(true);
      setQuery(q);
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      // save the cancel token for the current request
      cancelToken = axios.CancelToken.source();
      const { value } = await props.getAppUsers({
        count: 10,
        page: 1,
        query: q,
        token: cancelToken.token,
      });
      if (value) {
        // let data = appUsersData.concat(value?.Data);
        setAppUsersData(value?.Data);
        setLoader(false);
      } else {
        setAppUsersData([]);
        setLoader(false);
      }
    }
  };

  const removeUsers = (id) => {
    let test = eventAppUsers;
    let index = eventAppUsers?.findIndex((u) => u.Id === parseInt(id));
    if (index > -1) {
      test.splice(index, 1);
    }
    setEventAppUsers([...test]);
    setSelectedUsers(test);
  };
  const handleUsers = (e) => {
    let test = eventAppUsers;
    let index = eventAppUsers?.findIndex(
      (u) => u?.Id === parseInt(e.target.value)
    );
    if (index > -1) {
      test.splice(index, 1);
      setEventAppUsers([...test]);
    } else {
      let data = appUsers.find(
        (u) => u?.AppUser?.Id === parseInt(e.target.value)
      );
      setEventAppUsers([...test, data?.AppUser]);
    }
    // setEventAppUsers(test);
    setSelectedUsers(test);
  };
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Liontree",
      description: message,
    });
  };
  const optimizedFn = useCallback(debounce(handleChange), []);

  return (
    <>
      <div className="custom__container">
        <div className="custom__wrap">
          <div className="form-group inline-form-row">
            <div className="label-block">
              <label className="label-primary">Visible in the app?</label>
            </div>
            <div className="input-block">
              <div className="custom__radio__group">
                {/* <input
                  className="custom__radio__group--button"
                  type="radio"
                  value="month"
                  name="selectedPlan"
                  id="show"
                />
                <label className="custom__radio__group--label" htmlFor="show">
                  Show
                </label>
                <input
                  className="custom__radio__group--button"
                  type="radio"
                  value="year"
                  name="selectedPlan"
                  id="hide"
                /> */}
                {/* <label className="custom__radio__group--label" htmlFor="hide">
                  Hide
                </label> */}
                {activeStatus.map((d, i) => {
                  return (
                    <>
                      <input
                        className="custom__radio__group--button"
                        id={d.key}
                        type="radio"
                        checked={selectedStatus === d.key}
                        onClick={() => handleChange("VisibleInApp", d.key)}
                      />
                      <label
                        className="custom__radio__group--label"
                        htmlFor={d.key}
                      >
                        {d.value}
                      </label>
                    </>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="label-primary">
              Event Name <span className="required">*</span>
            </label>
            <input
              name="Name"
              value={eventData?.Name}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              type="text"
              className="form-control material-textfield-input"
            />
          </div>

          <div className="form-group">
            <label className="label-primary">
              Cover Photo (recommended dimensions){" "}
            </label>
            {/* <Dropzone
              accept="image/*"
              multiple={true}
              disabled={this.state.previewArr.length >= 50}
              onDrop={acceptedFiles =>
                this.addImages(acceptedFiles, values.images, setFieldValue)
              }
            >

              {({ getRootProps, getInputProps }) => (
                <section className="dropzone">
                  <div className="dropzone__area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <button type="button" className="btn btn__green">
                      {this.state.loader ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <>
                          <Plus className="btn__icon" /> Upload Photo
                        </>
                      )}
                    </button>
                  </div>
                </section>
              )}
            </Dropzone> */}

            {imageError && (
              <>
                <div className="invalid-feedback">
                  Please upload an image that in 10mb or smaller. You can use
                  the following compressors to make shrink your images:
                </div>
                <div className="invalid-feedback">Maximum 50 photos</div>
              </>
            )}

            <div className="dropzone__grid">
              <div className="dropzone__grid--item">
                {eventData?.ImageUrl !== null && eventData?.ImageUrl !== "" ? (
                  <div className="dropzone__grid--preview">
                    <img
                      className="preview__thumb"
                      src={eventData?.ImageUrl}
                      alt=""
                    />
                    <div className="dropzone__grid--remove">
                      <button className="btn btn__remove">
                        <Remove />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="dropzone__grid--preview">
                    <img
                      className="preview__thumb"
                      src="https://images.unsplash.com/photo-1662581871665-f299ba8ace07?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80"
                      alt=""
                    />
                    <div className="dropzone__grid--remove">
                      <button className="btn btn__remove">
                        <Remove />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="label-primary">
              Always allowed to view event even when event is not visable (this
              is for testing)
            </label>
            <ul className="form-list-item">
              {eventAppUsers?.length > 0 &&
                eventAppUsers?.map((u, i) => {
                  return (
                    <li>
                      <span>
                        {u?.FirstName} {u?.LastName}
                      </span>
                      <button
                        onClick={() => removeUsers(u?.Id)}
                        className="btn btn__delete--icon"
                      >
                        <Delete />
                      </button>
                    </li>
                  );
                })}
            </ul>
            <button className="btn btn__purple" onClick={openModal}>
              <Plus className="btn__icon" />
              Add to always allowed view
            </button>
          </div>

          <button
            onClick={() => addEvent()}
            type="submit"
            className="btn btn-lg btn__purple w-100"
          >
            {isLoading ? <Spin indicator={antIcon} /> : " Save & Update"}
          </button>
        </div>

        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">App Users</h5>
              <button className="btn react-modal-close" onClick={closeModal}>
                <Close />
              </button>
            </div>
            <div className="react-modal-body react-modal-body-style-2">
              <div className="form-group">
                {/* <input
                  type="text"
                  name=""
                  placeholder="Search by name, email, or phone number..."
                  className="form-control"
                  required
                /> */}
                <input
                  name="query"
                  onChange={(e) => {
                    setQuery(e.target.value);
                    optimizedFn(e.target.name, e.target.value);
                  }}
                  // onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={query}
                  placeholder="Search by name, email, or phone number..."
                  className="form-control"
                />
              </div>
              <div className="margin-minus-15">
                <div className="modal-list-container h-md">
                  {loader ? (
                    <>
                      <div className="loader-container">
                        <Spin
                          size="large"
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 30, color: "#000" }}
                              spin
                            />
                          }
                          className=""
                        />
                      </div>
                    </>
                  ) : (
                    appUsers?.length > 0 &&
                    appUsers?.map((option, index) => {
                      return (
                        <div key={index} className="modal-list-items">
                          <CheckboxComponent
                            index={index}
                            key={eventAppUsers}
                            value={option?.AppUser}
                            handleFeature={(e) => handleUsers(e)}
                            featureArray={eventAppUsers}
                          />
                        </div>
                      );
                    })
                  )}
                  {/* {appUsers?.length > 0 &&
                    appUsers?.map((u, i) => {
                      return (
                          <label className="custom__checkbox">
                            <input
                              type="checkbox"
                              className="custom__checkbox--input"
                            />
                            <span className="custom__checkbox--check"></span>
                            {u?.AppUsers?.FirstName} {u?.AppUsers?.LastName}
                          </label>
                        </div>
                      );
                    })} */}
                  {appUsers?.length <= 0 && (
                    <div className="table__blank">
                      <div className="table__blank--text">No users</div>
                    </div>
                  )}
                  {/* <div className="modal-list-items">
                    <label className="custom__checkbox">
                      <input
                        type="checkbox"
                        className="custom__checkbox--input"
                      />
                      <span className="custom__checkbox--check"></span>
                      Show in Mobile App
                    </label>
                  </div>
                  <div className="modal-list-items">
                    <label className="custom__checkbox">
                      <input
                        type="checkbox"
                        className="custom__checkbox--input"
                      />
                      <span className="custom__checkbox--check"></span>
                      Show in Mobile App
                    </label>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => closeModal()}
                type="submit"
                className="btn btn-lg btn__purple w-100 ms-0"
              >
                Save & Update
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    </>
  );
}
