import { connect } from "react-redux";
import { getMe, editProfile, getUserDetails } from "../../store/home/duck";
import EditAccountComponent from "./component";

const EditAccountComponentContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    getMe,
    editProfile,
    getUserDetails,
  }
)(EditAccountComponent);

export default EditAccountComponentContainer;
