import React from "react";

export default function CheckboxComponent(props) {
  return (
    <>
      <label className="custom__checkbox">
        {props.value}
        <input
          className="custom__checkbox--input"
          type="checkbox"
          id={props.index}
          value={props.value}
          onChange={props.handleCategoriesList}
          checked={props.categoriesArray.includes(props.value)}
        />
        <span className="custom__checkbox--check"></span>
      </label>
    </>
  );
}
