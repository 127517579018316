import React from "react";
import Dropzone from "react-dropzone";
import ReactModal from "react-modal";
import { Close, Delete, Plus, Remove } from "../../../components/svgIcons";
import ActionMore from "../../../components/common/ActionMore/component";

export default function AgendaComponent(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="page__header">
        <div className="page__header--container">
          <div className="page__header--row align-items-center">
            <div className="page__header--left">
              <label className="custom__checkbox">
                <input
                  name="ShowAgendaInApp"
                  onChange={(e) => props?.updateEventSettingData(e)}
                  checked={props?.eventSettingData?.ShowAgendaInApp}
                  type="checkbox"
                  className="custom__checkbox--input"
                />
                <span className="custom__checkbox--check"></span>
                Show section tab in Mobile App
              </label>
            </div>
            <div className="page__header--right">
              <button type="button" className="btn btn__purple">
                <Plus className="btn__icon" /> Add Agenda Item
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="custom__container">
        <div className="table-responsive table__responsive--custom">
          <table className="table table__custom">
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Start Time</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="underline">Welcome Reception</td>
                <td>Jan 1, 2023</td>
                <td>3:00am MT</td>
                <td>
                  <ActionMore />
                </td>
              </tr>
              <tr>
                <td className="underline">
                  Official Program Kick-off & Keynote Session
                </td>
                <td>Jan 1, 2023</td>
                <td>3:00am MT</td>
                <td>
                  <ActionMore />
                </td>
              </tr>
              <tr>
                <td className="underline">
                  Dinner followed by Special Performance & After-Party
                </td>
                <td>Jan 1, 2023</td>
                <td>3:00am MT</td>
                <td>
                  <ActionMore />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* <div className="table__blank">
          <div className="table__blank--text">No users</div>
        </div> */}

        <div className="table__pagination">
          <div className="table__pagination--results">3 results</div>
          <div className="table__pagination--nav">
            <button
              type="button"
              className="btn btn__default table__pagination--prev disabled"
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn__default table__pagination--next disabled"
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">App Users</h5>
            <button className="btn react-modal-close" onClick={closeModal}>
              <Close />
            </button>
          </div>
          <div className="react-modal-body react-modal-body-style-2">
            <div className="form-group">
              <input
                type="text"
                name=""
                placeholder="Search by name, email, or phone number..."
                className="form-control"
                required
              />
              <div className="invalid-feedback">Error</div>
            </div>
            <div className="margin-minus-15">
              <div className="modal-list-container">
                <div className="modal-list-items">
                  <label className="custom__checkbox">
                    <input
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Show in Mobile App
                  </label>
                </div>
                <div className="modal-list-items">
                  <label className="custom__checkbox">
                    <input
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Show in Mobile App
                  </label>
                </div>
                <div className="modal-list-items">
                  <label className="custom__checkbox">
                    <input
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Show in Mobile App
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="react-modal-footer">
            <button type="submit" className="btn btn-lg btn__purple w-100">
              Save & Update
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
