import React from "react"
import AppUsers from "./AppUsers/component"
import Close from "../../components/svgIcons/Close"

// import Styles from "./AppUser.module.css"

export default function AppUserComponent() {
  return (
    <>
      <main className="main" role="head">
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button className="btn btn__close">
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">Events Details</div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="tabs__links tabs__links--space">
          <button className="tabs__link active">Events</button>
          <button className="tabs__link">App Users</button>
        </div>
        <AppUsers />
      </main>
    </>
  )
}
