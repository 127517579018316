import { connect } from "react-redux";
import { appUserDelete, addUser, getUserDetails } from "../../store/home/duck";
import AddEditAppUserComponent from "./component";

const AddEditAppUserContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    addUser,
    getUserDetails,
    appUserDelete,
  }
)(AddEditAppUserComponent);

export default AddEditAppUserContainer;
