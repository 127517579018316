import { connect } from "react-redux";
import { CampaignAnalytics } from "../../store/home/duck";
import UploadAnalyticsComponent from "./component";

const UploadAnalyticsContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    CampaignAnalytics,
    
  }
)(UploadAnalyticsComponent);

export default UploadAnalyticsContainer;
