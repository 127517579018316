import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { Close, Plus, Remove } from "../../components/svgIcons";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { isEmpty, get, sortBy, set } from "lodash";
import ReactModal from "react-modal";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Dropzone from "react-dropzone";
import s from "./admin.module.css";
import CKEditor from "@ckeditor/ckeditor5-react";
import imageCompression from "browser-image-compression";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { v4 as uuid } from "uuid";
// import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"
// import SourceEditing from '../SourceEditing/index.js'
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
const antIconOne = (
  <LoadingOutlined style={{ fontSize: 24, color: "#000" }} spin />
);
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;
const jwtToken = localStorage.getItem("Authorization");
export default function AdminComponent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState("theme");
  const [introLongDescription, setIntroLongDescription] = useState("");
  const [introPhoto, setIntoPhoto] = useState("");
  const [stockPhoto, setStockPhoto] = useState("");
  const [WeeklyUpdateImage, setWeeklyUpdateImage] = useState(
    "https://ltuatwestus2storpublic.blob.core.windows.net/lt-uat-westus2-ltweekly-image/unnamed%20%281%29_20233962243875.png"
  );
  const [WeeklyUpdateImagePreview, setWeeklyUpdateImagePreview] = useState({});
  const [WeeklyUpdateImageError, setWeeklyUpdateImageError] = useState(false);
  const [WeeklyUpdateImageLoader, setWeeklyUpdateImageLoader] = useState(false);
  const [isPlainTextChecked, setIsPlainTextChecked] = useState(false);
  const [introductionData, setIntroductionData] = useState({
    Id: 0,
    ItemId: uuid(),
    Category: "",
    EmailText: "",
    CampaignId: "",
    ItemName: moment(new Date()).format("MM-DD-YYYY"),
    EmailSubject: "LionTree: Top Themes of the Week",
    WeeklyUpdateImage:
      "https://ltuatwestus2storpublic.blob.core.windows.net/lt-uat-westus2-ltweekly-image/unnamed%20%281%29_20233962243875.png",
    Created: moment().format("Y-MM-DD HH:mm"),
    ShowSubscriptionInEmail: true,
    ShowSignatureInEmail: true,
    CampaignName: "",
    CampaignType: "",
    MailingListId: "",
    WeeklyDate: "",
    PlainTextChecked: false,
  });
  const [ltWeeklyItemData, setLtWeeklyItemData] = useState([]);
  const [agoloNewsData, setAgoloNewsData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [curatedList, setCuratedList] = useState([]);
  const [selectedCuratedValue, setSelectedCuratedValue] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCalled, setIsCallApi] = useState(false);
  const [introImageLoader, setIntroImageLoader] = useState(false);
  const [introStockImagePreview, setStockPhotoPreview] = useState({});
  const [stockImageError, setStockImageError] = useState(false);
  const [introStockImageLoader, setIntroStockLoader] = useState(false);
  const [isLtWeeklyData, setIsLtWeeklyData] = useState(true);
  const [imageError, setImageError] = useState(false);
  const [introImagePreview, setIntroImagePreview] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [introductionError, setIntroductionError] = useState("");
  const [deletedRecord, setDeletedRecord] = useState(-1);
  const state = location?.state;
  const [imageUploadPendingCount, setImageUploadPendingCount] = useState(0);
  const [emailModal, setEmailModal] = useState(false);
  const [mailingListData, setMailingListData] = useState([]);
  const [selectedMailingList, setSelectedMailingList] = useState([]);
  const [userListId, setUserListId] = useState("");
  const [campaignTitle, setCampaignTitle] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [photoError, setPhotoError] = useState("");
  const [appTitleError, setAppTitleError] = useState("");
  const [openWebsiteModal, setOpenWebsiteModal] = useState(false);
  const [campaignTitleError, setCampaignTitleError] = useState("");
  const [openAppModal, setOpenAppModal] = useState(false);
  const [campaignType, setCampaignType] = useState("Test");
  const [isLive, setIsLive] = useState(false);
  const [mailingListError,setMailingListError]=useState("")
  // Slect End
  const closeModal = () => {
    setDeleteModal(false);
    setDeletedRecord(-1);
    setSelectedCuratedValue([]);
    setIsOpen(false);
  };

  const closeNewsModal = () => {
    setOpenWebsiteModal(false);
    setOpenAppModal(false);
  };

  const [previousPathname, setPreviousPathname] = useState("");

  const removeTheme = async () => {
    let key = deletedRecord;
    let data = ltWeeklyItemData[key];
    const findIndex = ltWeeklyItemData.findIndex(
      (a) => a.ItemId === data.ItemId
    );
    findIndex !== -1 && ltWeeklyItemData.splice(findIndex, 1);
    setLtWeeklyItemData([...ltWeeklyItemData]);
    setDeleteModal(false);
  };
  // Select Start
  const animatedComponents = makeAnimated();
  useEffect(() => {
    setPreviousPathname(location.pathname);
  }, [agoloNewsData, introductionData, curatedList, location.pathname]);

  const changeTab = async (tab) => {
    setTab(tab);
    if (tab === "news") {
      // navigate("/home-news");
    } else {
      // navigate("/home-theme");
    }
  };

  const closeEmailModal = () => {
    setEmailModal(false);
    setUserListId("")
    setCampaignTitleError("")
    setMailingListError("")
  };

  const CallApiForGetMailingList = async () => {
    const { value } = await props.getMailingList({
      page: 1,
      count: 20,
    });
    if (value?.Result?.Data?.length > 0) {
      setMailingListData(value?.Result?.Data);
      // handleMailingList(value?.Result?.Data[0]);
    }
  };
  const handleMailingList = (list) => {
    setSelectedMailingList(list);
    setUserListId(list?.UserListIds);
  };
  const handleCampaignTitle = (title) => {
    setCampaignTitleError("");
    setCampaignTitle(title);
  };

  const getSendEmailInformations = async (id) => {
    const { value } = await props.getSendEmailInformations({ id: id });
    if (value.Success) {
      const data = introductionData;
      setWeeklyUpdateImage(value?.Result?.WeeklyUpdateImage);
      let prm = Object.keys(value.Result).forEach((key) => {
        data[key] = value.Result[key];
      });
      // await Promise.all(prm)
      setIntroductionData({ ...data });
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!isCalled) {
      let id = location.pathname.split("/")[2];
      if (id) {
        setLoader(true);
        getSendEmailInformations(id);
        setIsCallApi(true);
      }
      // CallApiForGetMailingList();
    }
  }, [agoloNewsData, ltWeeklyItemData, introductionData, curatedList]);

  const custom_config = {
    extraPlugins: [MyCustomUploadAdapterPlugin],
    toolbar: {
      sourceEditing: true,
      shouldNotGroupWhenFull: true,
      mediaEmbed: false,
      table: false,
      imageResize: false,
    },
    image: {
      styles: {
        options: [],
      },
    },
    removePlugins: ["MediaEmbed", "Table", "ImageInsert"],
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "sourceEditing",
      ],
    },
  };
  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader, this.props);
    };
  }

  const handleCamoaignType = (value) => {
    setUserListId("")
    setCampaignType(value);
    if(value === "Live"){
      setIsLive(true)
    }else{
      setIsLive(false)
    }
  };

  class MyUploadAdapter {
    constructor(props) {
      // CKEditor 5's FileLoader instance.
      this.loader = props;
      // URL where to send files.
      this.url = `${HOSTNAME}/Dashboard/AddImage`;
    }
    // Starts the upload process.
    upload() {
      return new Promise((resolve, reject) => {
        this._initRequest();
        this._initListeners(resolve, reject);
        this._sendRequest();
      });
    }
    // Aborts the upload process.
    abort() {
      if (this.xhr) {
        this.xhr.abort();
      }
    }
    // Example implementation using XMLHttpRequest.
    _initRequest() {
      const xhr = (this.xhr = new XMLHttpRequest());
      xhr.open("PUT", this.url, true);
      xhr.responseType = "json";
      let token = "";
      if (!jwtToken) {
        token = localStorage.getItem("Authorization");
      } else {
        token = jwtToken;
      }
      let auth = "Bearer " + token;
      xhr.setRequestHeader("Content-type", "application/json");
      //xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
      xhr.setRequestHeader("Authorization", auth);
    }
    // Initializes XMLHttpRequest listeners.
    _initListeners(resolve, reject) {
      const xhr = this.xhr;
      const loader = this.loader;
      const genericErrorText =
        "Couldn't upload file:" + ` ${loader.file.name}.`;
      xhr.addEventListener("error", () => reject(genericErrorText));
      xhr.addEventListener("abort", () => reject());
      xhr.addEventListener("load", () => {
        const response = xhr.response;
        if (!response || response.error) {
          return reject(
            response && response.error
              ? response.error.message
              : genericErrorText
          );
        }
        let count = imageUploadPendingCount - 1;
        if (count > 0) {
          setImageUploadPendingCount(count);
        } else {
          setImageUploadPendingCount(0);
        }
        // If the upload is successful, resolve the upload promise with an object containing
        // at least the "default" URL, pointing to the image on the server.
        // ltWeeklyItemData[index] = data
        // setLtWeeklyItemData([...ltWeeklyItemData])
        resolve({
          default: response,
        });
      });
      if (xhr.upload) {
        xhr.upload.addEventListener("progress", (evt) => {
          if (evt.lengthComputable) {
            loader.uploadTotal = evt.total;
            loader.uploaded = evt.loaded;
          }
        });
      }
    }
    _sendRequest() {
      const data = new FormData();
      this.loader.file.then((result) => {
        let reader = new FileReader();
        var self = this;
        reader.onloadend = async () => {
          let baseVal = reader.result
            ?.replaceAll("data:image/png;base64,", "")
            .replaceAll("data:image/jpeg;base64,", "");
          let ext = result.type.split("/")[1];
          let fileName = result.name?.replaceAll(`.${ext}`, "");
          let pay = { Content: baseVal, Extention: ext, FileName: fileName };
          // console.log(pay,'pay')
          self.xhr.send(JSON.stringify(pay)); // Make sure to stringify
          let count = imageUploadPendingCount + 1;
          setImageUploadPendingCount(count);
          // data.append('Content',baseVal)
          // data.append('Extention',ext)
          // self.xhr.send(data)
        };
        reader.readAsDataURL(result);
        //   data.append("file", result)
      });
    }
  }

  const removeIntroPhoto = () => {
    introductionData["Photo"] = "";
    setLtWeeklyItemData([...ltWeeklyItemData]);
    setIntoPhoto("");
    setIntroImagePreview({});
  };

  const removeStockPhoto = () => {
    setStockPhoto("");
    setStockPhotoPreview({});
  };

  const removeWeeklyUpdateImage = () => {
    setWeeklyUpdateImage("");
    setWeeklyUpdateImagePreview({});
  };

  const uploadImage = async (baseVal, extention, key, fileName = "") => {
    let count = imageUploadPendingCount + 1;
    setImageUploadPendingCount(count);
    let payload = {
      Content: baseVal,
      Extention: extention,
      FileName: fileName,
    };
    const { value } = await props.uploadImage(payload);
    if (value) {
      if (key === "Photo") {
        setIntoPhoto(value);
      } else if (key === "WeeklyUpdateImage") {
        setWeeklyUpdateImage(value);
      } else {
        setStockPhoto(value);
      }
      introductionData[key] = value;
      setIntroductionData({ ...introductionData });
      let count = imageUploadPendingCount - 1;
      if (count > 0) {
        setImageUploadPendingCount(count);
      } else {
        setImageUploadPendingCount(0);
      }
      // console.log(introductionData,'value1')
    }
  };

  const getModifyCuratedList = (value) => {
    const val = value?.split("|");
    let valList = [];
    let prm = val?.map((e, index) => {
      let isChecked = curatedList.filter((obj) => {
        return obj.value?.toString() === e;
      });
      if (isChecked.length > 0) {
        valList.push(isChecked[0]);
      }
    });
    if (val?.length > 0) Promise.all(prm);
    valList = sortBy(valList, "label");
    return valList;
  };

  const handleChange = (e) => {
    setSelectedCuratedValue(e);
  };

  const onEditorIntroChange = (val) => {
    // introductionData.LongDescription = val
    // console.log(introLongDescription,'beforddd')
    setIntroLongDescription(val);
  };

  const handleIntroChange = (val, key) => {
    introductionData[key] = val;
    setIntroductionData({ ...introductionData });
  };

  const updateLtweeklyData = async () => {
    // if(!introductionData?.ShortDescription){
    //   setAppTitleError("Please enter app title.")
    // }else if(!introductionData?.introPhoto && !introductionData?.Photo){
    //   setPhotoError("Please upload introduction photo.")
    // }else if (!introductionData?.LongDescription) {
    //   setIntroductionError("Please Enter Introduction.");
    // }
    // else {
    // if(introductionData?.LongDescription !== '' && introductionData?.LongDescription){
    let isError = false;
    let index = ltWeeklyItemData.findIndex((object) => {
      return object.ShortDescription !== "" && object.LongDescription === "";
    });
    let indexNew = ltWeeklyItemData.findIndex((object) => {
      return object.ShortDescription === "" && object.LongDescription !== "";
    });
    if (index > -1) {
      let pay = [
        {
          key: "LongDescription",
          ItemId: ltWeeklyItemData[index].ItemId,
          ErrorMessage: "Please Enter LongDescription",
        },
      ];
      isError = true;
      setErrorList([...pay]);
    }
    if (indexNew > -1) {
      // only splice array when item is found
      let pay = [
        {
          key: "ShortDescription",
          ItemId: ltWeeklyItemData[indexNew].ItemId,
          ErrorMessage: "Please Enter ShortDescription",
        },
      ];
      isError = true;
      setErrorList([...pay]);
    }
    if (!isError) {
      setIsLoading(true);
      introductionData["Photo"] = introPhoto;
      introductionData["StockItem"] = stockPhoto;
      let index = ltWeeklyItemData.findIndex((object) => {
        return object.ShortDescription === "" && object.LongDescription === "";
      });
      if (index > -1) {
        // only splice array when item is found
        ltWeeklyItemData.splice(index, 1); // 2nd parameter means remove one item only
        index = ltWeeklyItemData.findIndex((object) => {
          return (
            object.ShortDescription === "" && object.LongDescription === ""
          );
        });
        if (index > -1) {
          // only splice array when item is found
          ltWeeklyItemData.splice(index, 1);
        }
      }
      introductionData["LTWeeklyItem"] = ltWeeklyItemData;
      setLtWeeklyItemData([...ltWeeklyItemData]);
      // console.log(introductionData,'introductionData')
      const { value } = await props.AddLTWeekly(introductionData);
      if (value?.Success) {
        setIsLoading(false);
        openNotificationWithIcon("success", "Added LT Weekly Successfully.");
        navigate("/iim-weeklies");
      } else {
        setIsLoading(false);
        openNotificationWithIcon("error", value?.Message);
      }
      setErrorList([]);
    }
    // }
    // }
  };
  const onPlainTextChecked = (val) => {
    console.log(val, "val==>");
    setIsPlainTextChecked(val);
  };

  const handleChangeData = (key) => {
    return (event, editor) => {
      if (isLtWeeklyData) {
        if (key === "ShortDescription") {
          setAppTitleError("");
        } else if (key === "EmailText") {
          setIntroductionError("");
        }
        introductionData[key] = editor.getData();
        setIntroductionData({ ...introductionData });
      }
    };
  };

  const handleChangeDetails = (key, value) => {
    if (key === "FromEmail") {
      const updatedUsername = value?.replace(/@/g, "");
      const updatedEmail = `${updatedUsername}@liontree.com`;

      setIntroductionData({
        ...introductionData,
        [key]: updatedEmail,
      });
    } else {
      setIntroductionData({
        ...introductionData,
        [key]: value,
      });
    }
    
  };

  const campaignTypeOptions = [
    { value: "Live", label: "Live Campaign" },
    { value: "Test", label: "Test Campaign" },
  ];

  const addImages = async (acceptedFiles, keys, keyData) => {
    if (acceptedFiles.length > 0) {
      if (keyData === "StockItem") {
        setIntroStockLoader(true);
      } else if (keyData === "WeeklyUpdateImage") {
        setWeeklyUpdateImageLoader(true);
      } else {
        setIntroImageLoader(true);
      }
      setImageError("");
      setPhotoError("");
      if (acceptedFiles.length > 1) {
        const imageError = "Maximum 1 photo";
        setImageError(imageError);
      } else {
        acceptedFiles.map(async (data, key) => {
          if (data.type.split("/")[0] === "image") {
            let reader = new FileReader();
            reader.onloadend = async () => {
              let baseVal = reader.result
                ?.replaceAll("data:image/png;base64,", "")
                .replaceAll("data:image/jpeg;base64,", "");
              let ext = data.type.split("/")[1];
              let fileName = data.name?.replaceAll(`.${ext}`, "");
              await uploadImage(baseVal, ext, keyData, fileName);
              // await uploadImage(baseVal, ext, keyData)
              setIntroImageLoader(false);
              setWeeklyUpdateImageLoader(false);
              setIntroStockLoader(false);
            };
            reader.readAsDataURL(acceptedFiles[0]);
            acceptedFiles[key].preview = URL.createObjectURL(data);
            if (keyData === "StockItem") {
              setStockPhotoPreview(data);
            } else if (keyData === "WeeklyUpdateImage") {
              setWeeklyUpdateImagePreview(data);
            } else {
              setIntroImagePreview(data);
            }
          } else {
            setWeeklyUpdateImageLoader(false);
            setIntroStockLoader(false);
            if (keyData === "StockItem") {
              setStockImageError("Please use jpeg or png format.");
            } else if (keyData === "WeeklyUpdateImage") {
              setWeeklyUpdateImageError("Please use jpeg or png format.");
            } else {
              setImageError("Please use jpeg or png format.");
            }
            setIntroImageLoader(false);
          }
        });
      }
    } else {
      if (keyData === "StockItem") {
        setStockImageError("Please use jpeg or png format.");
      } else if (keyData === "WeeklyUpdateImage") {
        setWeeklyUpdateImageError("Please use jpeg or png format.");
      } else {
        setImageError("Please use jpeg or png format.");
      }
    }
  };

  const onIntroCheck = (val, key) => {
    introductionData[key] = !introductionData[key];
    setIntroductionData({ ...introductionData });
    setOpenWebsiteModal(false);
    setOpenAppModal(false);
  };

  const sendFreeFormLtWeeklyEmail = async () => {
    // if(userListId===""||userListId=="undefined"){
    //   setMailingListError("Please Select Mailing List")
    //   return
    // }
    let hasError = false;

    if (campaignTitle?.trim() === "") {
      setCampaignTitleError("Enter Campaign Title");
      hasError = true;
    } else {
      setCampaignTitleError("");
    }
  
    if (userListId === ""||userListId=="undefined") {
      setMailingListError("Please Select Mailing List");
      hasError = true;
    } else {
      setMailingListError("");
    }
  
    if (hasError) {
      return;
    }
    if (campaignTitle?.trim() !== "") {
      setIsLoading(true);
      if (isPlainTextChecked) {
        let title = campaignTitle + "PlainText" + campaignType;
        introductionData.CampaignName = title;
        introductionData.WeeklyUpdateImage = "";
        introductionData.CampaignType = campaignType;
        introductionData.MailingListId = selectedMailingList.Id;
        introductionData.WeeklyDate = "";
        introductionData.PlainTextChecked = isPlainTextChecked;
      } else {
        let title = campaignTitle + "FreeForm" + campaignType;
        introductionData.CampaignName = title;
        introductionData.WeeklyUpdateImage = WeeklyUpdateImage;
        introductionData.CampaignType = campaignType;
        introductionData.MailingListId = selectedMailingList.Id;
        introductionData.WeeklyDate = moment(new Date(), "MM DD YYYY").format(
          "MMMM DD, YYYY"
        );
        introductionData.PlainTextChecked = false;
      }
      const { value } = await props.sendFreeFormLtEmail(introductionData);
   
      if (value && value?.Success) {
        setIsLoading(false);
        setUserListId("")
        setCampaignTitleError("")
        setMailingListError("")
        navigate("/free-form-emails");
      } else {
        setIsLoading(false);
        navigate("/free-form-emails");
      }
    } else {
      setIsLoading(false);
      setCampaignTitleError("Please enter campaign title");
    }
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Liontree",
      description: message,
    });
  };

  const closeTab = () => {
    console.log(previousPathname, "previousPathname");
    // navigate(previousPathname)
    navigate(-1);
  };

  const username = introductionData?.FromEmail?.split("@")[0];
  // Slect End
  return (
    <>
      <main
        className="main viewother__news-wrapper"
        style={{ paddingBottom: "90px" }}
        role="head"
      >
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => closeTab()}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">Send Email</div>
                </div>
              </div>
            </div>
          </header>
        </div>

        {tab === "theme" && (
          <>
            {loader && (
              <Spin
                size="large"
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 30, color: "#000" }}
                    spin
                  />
                }
                className="loader__full"
              />
            )}
            <br />
            <div className="custom__container" style={{ maxWidth: 752 }}>
              <div className="d-flex flex-column gap-3 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label className="custom__checkbox">
                    <input
                      // name="ShowSubscriptionInEmail"
                      value={isPlainTextChecked ? true : false}
                      checked={isPlainTextChecked}
                      onChange={(e) => onPlainTextChecked(e.target.checked)}
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Send Plain Text Email
                  </label>
                  <button
                    type="submit"
                    disabled={imageUploadPendingCount > 0 ? true : false}
                    // className={`btn ${s.BtnSave} `}
                    onClick={() => {
                      setEmailModal(!emailModal);
                      CallApiForGetMailingList();
                    }}
                    className={`btn btn-md btn__purple w-20 ${s.DarkDisabled}`}
                  >
                    Send Email
                  </button>
                </div>
                {!isPlainTextChecked && (
                  <div className="d-flex flex-column gap-3 mb-3 margtop-minus">
                    <label className="custom__checkbox">
                      <input
                        name="ShowSubscriptionInEmail"
                        value={introductionData?.ShowSubscriptionInEmail}
                        checked={introductionData?.ShowSubscriptionInEmail}
                        onChange={(e) =>
                          onIntroCheck(
                            e.target.value,
                            "ShowSubscriptionInEmail"
                          )
                        }
                        type="checkbox"
                        className="custom__checkbox--input"
                      />
                      <span className="custom__checkbox--check"></span>
                      Subscription Sign up Request
                    </label>
                    <label className="custom__checkbox">
                      <input
                        name="ShowSignatureInEmail"
                        value={introductionData?.ShowSignatureInEmail}
                        checked={introductionData?.ShowSignatureInEmail}
                        onChange={(e) =>
                          onIntroCheck(e.target.value, "ShowSignatureInEmail")
                        }
                        type="checkbox"
                        className="custom__checkbox--input"
                      />
                      <span className="custom__checkbox--check"></span>
                      Signature
                    </label>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className="label-primary">From</label>
                <div className="form-group mt-2 d-flex align-items-center">
                  <input
                    type="text"
                    className="form-control me-2"
                    value={username?username:""}
                    onChange={(e) =>
                      handleChangeDetails("FromEmail", e.target.value)
                    }
                  />
                  <span className="domain-text">@liontree.com</span>
                </div>
              </div>
              <div className="form-group">
                <label className="label-primary">Email Subject</label>
                <div className="form-group mt-2">
                  <input
                    type="text"
                    className="form-control"
                    value={introductionData?.EmailSubject}
                    onChange={(e) =>
                      handleChangeDetails("EmailSubject", e.target.value)
                    }
                  />
                </div>
              </div>
              {!isPlainTextChecked && (
                <div className="form-group" style={{ maxWidth: 404 }}>
                  <label className="label-primary">Header Photo</label>
                  <Dropzone
                    accept="image/*"
                    multiple={false}
                    onDrop={(acceptedFiles) =>
                      addImages(acceptedFiles, "Intro", "WeeklyUpdateImage")
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="dropzone">
                        <div className="dropzone__area" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <button type="button" className="btn btn__purple">
                            {WeeklyUpdateImageLoader ? (
                              <Spin indicator={antIcon} />
                            ) : (
                              <>
                                <Plus className="btn__icon" /> Upload Photo
                              </>
                            )}
                          </button>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {WeeklyUpdateImage !== "" && WeeklyUpdateImage !== null && (
                    <div className="dropzone__grid">
                      <div
                        className="dropzone__grid--item"
                        style={{ width: 90 }}
                      >
                        <div className="dropzone__grid--preview">
                          {!isEmpty(WeeklyUpdateImagePreview) &&
                          get(WeeklyUpdateImagePreview, "preview", "") ? (
                            <img
                              className="preview__thumb"
                              src={get(WeeklyUpdateImagePreview, "preview", "")}
                              alt=""
                            />
                          ) : WeeklyUpdateImage?.includes(
                              "blob.core.windows.net"
                            ) ? (
                            <img
                              className="preview__thumb"
                              src={WeeklyUpdateImage}
                              alt=""
                            />
                          ) : (
                            <img
                              className="preview__thumb"
                              src={`${HOSTNAME}` + WeeklyUpdateImage}
                              alt=""
                            />
                          )}

                          <div className="dropzone__grid--remove">
                            <button
                              onClick={() => removeWeeklyUpdateImage()}
                              className="btn btn__remove"
                            >
                              <Remove />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {WeeklyUpdateImageError !== "" && (
                    <>
                      <div className="invalid-feedback">
                        {WeeklyUpdateImageError}
                      </div>
                    </>
                  )}
                </div>
              )}
              <div className="form-group">
                <label className="label-primary">Email Text</label>
                <div className="editor-instance ck-editor-updates">
                  <CKEditor
                    editor={Editor}
                    data={introductionData?.EmailText}
                    config={custom_config}
                    onChange={handleChangeData("EmailText")}
                    onInit={(editor) => {
                      editor.ui
                        .getEditableElement()
                        .parentElement.insertBefore(
                          editor.ui.view.toolbar.element,
                          editor.ui.getEditableElement()
                        );
                    }}
                  />
                </div>
                {introductionError !== "" && (
                  <>
                    <div className="invalid-feedback">
                      Please enter Email Text
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* <div className={s.ThemeFooter}>
            <button 
              disabled={imageUploadPendingCount > 0 ? true: false}
              // onClick={()=>updateLtweeklyData()}
              className={`btn ${s.BtnSave} ${s.DarkDisabled}`} 
            >
              {isLoading ? <Spin indicator={antIconOne} /> : "Save"}
            </button>    
          </div> */}
          </>
        )}
        <ReactModal
          isOpen={deleteModal}
          onRequestClose={closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Delete</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to delete this ? This cannot be undone.
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={closeModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => removeTheme()}
                className="btn btn__purple ms-2"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Yes, Delete"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={emailModal}
          onRequestClose={closeEmailModal}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered send__other-news-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Send LT Weekly Email</h5>
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <label className="label-primary">Campaign Title</label>
                <input
                  type="text"
                  name="campaignTitle"
                  onChange={(e) => handleCampaignTitle(e.target.value)}
                  className="form-control"
                  value={campaignTitle}
                  required
                />
                {campaignTitleError !== "" && (
                  <>
                    <div className="invalid-feedback">{campaignTitleError}</div>
                  </>
                )}
              </div>
              <div className="send__email-input">
                <label className="label-primary">Mailing List</label>
                <select
                  className="form-select"
                  aria-label="Select Mailing List"
                  onChange={(e) =>{
                    if(e.target.value){
                    handleMailingList(mailingListData[e.target.value])
                    setMailingListError("")
                    }
                  }
                  }
                >
                  <option value={-1}> Select Email List</option>
                   {mailingListData?.map((list, index) => {
                    if(list?.IsLive == isLive){
                      return <option value={index}>{list?.MailingDate}</option>
                    }
                  })}
                </select>
                <div
                  style={{
                    marginTop: "13px",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  <span>
                    {/* {userListId === ""
                      ? ""
                      : parseInt(userListId)?.toLocaleString("en-US")}{" "}
                    people */}
                     {userListId === "" || !userListId
                      ? ""
                      : parseInt(
                          selectedMailingList?.UserListIds
                        )?.toLocaleString("en-US")}{" "}
                    {userListId
                      ? parseInt(userListId) > 1
                        ? "Peoples"
                        : "People"
                      : ""}
                  </span>
                </div>
              </div>
              {mailingListError !== "" && (
                  <>
                    <div className="invalid-feedback">{mailingListError}</div>
                  </>
                )}
              <br />
              <div className="table-radio-container-list">
                {campaignTypeOptions.map((j) => {
                  return (
                    <div className="form--inline-radio">
                      <label className="custom__radio">
                        {" "}
                        {j.label}
                        <input
                          className="custom__radio--input"
                          type="radio"
                          checked={campaignType === j.value}
                          onChange={() => {
                            handleCamoaignType(j.value);
                          }}
                        />
                        <span className="custom__radio--check"></span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => {
                  closeEmailModal();
                  setUserListId("");
                  setCampaignType("Test");
                  setCampaignTitle("");
                }}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                onClick={() => sendFreeFormLtWeeklyEmail()}
                type="button"
                className="btn btn__purple"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Send"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={openWebsiteModal}
          onRequestClose={closeNewsModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Show Website Toggle Alert</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to show IIM LT Weekly on the Website ?
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={closeNewsModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => onIntroCheck(true, "ShowInWebsite")}
                className="btn btn__orange ms-2"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Yes"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={openAppModal}
          onRequestClose={closeNewsModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Show App Toggle Alert</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to IIM LT Weekly on the App ?
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={closeNewsModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => onIntroCheck(true, "ShowInApp")}
                className="btn btn__orange ms-2"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Yes"}
              </button>
            </div>
          </div>
        </ReactModal>
      </main>
    </>
  );
}
