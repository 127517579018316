import React from "react";
import { HolderOutlined } from "@ant-design/icons";
import ReactModal from "react-modal";
import { Plus, Setting } from "../../../components/svgIcons";

export default function PhotosVideosComponent(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="page__header">
        <div className="page__header--container">
          <div className="page__header--row align-items-center">
            <div className="page__header--left">
              <label className="custom__checkbox">
                <input
                  name="ShowPhotosVideosInApp"
                  onChange={(e) => props?.updateEventSettingData(e)}
                  checked={props?.eventSettingData?.ShowPhotosVideosInApp}
                  type="checkbox"
                  className="custom__checkbox--input"
                />
                <span className="custom__checkbox--check"></span>
                Show section tab in Mobile App{" "}
              </label>
            </div>
            <div className="page__header--right">
              <button type="button" className="btn btn__default">
                <Setting className="btn__icon" /> Add New User to Event
              </button>
              <button
                type="button"
                className="btn btn__purple"
                onClick={openModal}
              >
                <Plus className="btn__icon" /> Upload Photo
              </button>
            </div>
          </div>
          <div className="tab_btn--container">
            <button className="tab__btn active">Photos</button>
            <button className="tab__btn">Videos</button>
          </div>
        </div>
      </div>
      <div className="custom__container">
        <div className="table-responsive table__responsive--custom">
          <table className="table table__custom items-order-table">
            <thead>
              <tr>
                <th></th>
                <th>Tagged Users</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="underline">
                  <HolderOutlined className="reorderIcon" />
                  <img
                    className="photos-video-thumb"
                    src="https://images.unsplash.com/photo-1662581871665-f299ba8ace07?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80"
                  />
                  8edjjfdd.png
                </td>
                <td>
                  <HolderOutlined className="reorderIcon" /> Josh Adams, Jane
                  Doe
                </td>
              </tr>
              <tr>
                <td className="underline">
                  <HolderOutlined className="reorderIcon" />
                  <img
                    className="photos-video-thumb"
                    src="https://images.unsplash.com/photo-1662581871665-f299ba8ace07?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80"
                  />
                  9399393.png
                </td>
                <td>
                  <HolderOutlined className="reorderIcon" />-
                </td>
              </tr>
              <tr>
                <td className="underline">
                  <HolderOutlined className="reorderIcon" />
                  <img
                    className="photos-video-thumb"
                    src="https://images.unsplash.com/photo-1662581871665-f299ba8ace07?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80"
                  />
                  picture.png
                </td>
                <td>
                  <HolderOutlined className="reorderIcon" />
                  James Smith, Wendy Daniels
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <div className="table__blank">
          <div className="table__blank--text">No users</div>
        </div> */}

        <div className="table__pagination">
          <div className="table__pagination--results">3 results</div>
          <div className="table__pagination--nav">
            <button
              type="button"
              className="btn btn__default table__pagination--prev disabled"
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn__default table__pagination--next disabled"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Photo Heading text</h5>
            {/* <button className="btn react-modal-close" onClick={closeModal}><Close /></button> */}
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">Heading Title</label>
              <input type="text" className="form-control" />
            </div>
          </div>
          <div className="react-modal-footer">
            <button className="btn btn__default" onClick={closeModal}>
              Cancel
            </button>
            <button type="submit" className="btn btn__purple">
              Update
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
