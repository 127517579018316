import { connect } from "react-redux";
import { removeMailingList, updateMailingList, getMailingListById } from "../../store/home/duck";
import AdminComponent from "./component";

const AdminComponentContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
  	removeMailingList,
  	updateMailingList,
    getMailingListById,
  }
)(AdminComponent);

export default AdminComponentContainer;
