import { connect } from "react-redux";
import { GetUsers } from "../../store/home/duck";
import UserComponent from "./component";

const UserContainer = connect(
  // Map state to props
  (state) => ({
  }),
  // Map actions to dispatch and props
  {
    GetUsers
  }
)(UserComponent);

export default UserContainer;
