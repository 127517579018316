import React, { useEffect, useState } from "react";
import { Camera, Close, Delete, Plus } from "../../components/svgIcons";
import Logo from "../../components/svgIcons/Logo";
import ProfileComponent from "../Account/Profile/component";
import ReactModal from "react-modal";
import { notification } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PhoneInput, {
  formatPhoneNumber,
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";
export default function AddEditAppUserComponent(props) {
  const [userData, setUserData] = useState({});
  const [addUserData, setAddUserData] = useState({
    Email: "",
    CountryCode: "+1",
    PhoneNumber: "",
    FirstName: "",
    LastName: "",
    Role: "seeker",
    Title: "",
    Headline: "",
    Bio: "",
    Contacts: [],
  });
  const [isModalOpen, setModelOpen] = useState(false);
  const [isCalled, setIsCalled] = useState(false);
  const [emailPhoneNumberList, setEmailPhoneNumberList] = useState([]);
  const location = useLocation();
  const params = useParams();
  const userId = parseInt(params?.id);
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const callApi = async (id) => {
    const { value } = await props.getUserDetails({ Id: id });
    if (value?.Data?.length > 0) {
      setUserData(value?.Data[0]);
      setEmailPhoneNumberList(value?.Data[0]?.AppUserDetails);
    }
  };
  useEffect(() => {
    const { state } = location;
    // setUserData(state?.data);
  });

  const handleChange = (name, value) => {
    userData.AppUsers[name] = value;
    setUserData({ ...userData });
  };

  function extractPhone(countryCode = "", phoneNumber = "") {
    return phoneNumber.replace(countryCode, "");
  }

  const handleAddUserChange = (name, value) => {
    // if (name === "PhoneNumber") {
    //   let data = phoneValidation(
    //     getCountryCallingCode(addUserData?.CountryCode),
    //     value
    //   );
    //   value = data.phone;
    // }
    if (name === "CountryCode") {
      value = "+" + getCountryCallingCode(value);
    }
    if (name === "Email") {
      setEmailError("");
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        setEmailError("Invalid email address");
      }
    }
    addUserData[name] = value;
    setAddUserData({ ...addUserData, [name]: value });
  };
  function phoneValidation(countryDialCode, phoneNumber) {
    var phoneNumberExtract = "";
    if (phoneNumber != undefined) {
      phoneNumberExtract = extractPhone(countryDialCode, phoneNumber);
    }
    var obj = {};
    if (phoneNumberExtract == "") {
      obj.status = false;
    } else {
      obj.status = true;
    }
    obj.phone = phoneNumberExtract;
    obj.cc = countryDialCode;
    return obj;
  }

  const addUser = async () => {
    addUserData["Contacts"] = emailPhoneNumberList;
    if (addUserData?.FirstName === "") {
    } else if (addUserData?.LastName === "") {
    } else if (addUserData?.Email === "" || emailError !== "") {
    } else if (addUserData?.Role === "") {
    } else {
      let phoneNumber = extractPhone(
        addUserData?.CountryCode,
        addUserData?.PhoneNumber
      );
      addUserData["PhoneNumber"] = phoneNumber;
      // let countryDialCode =
      //   "+" + getCountryCallingCode(addUserData?.CountryCode);
      // addUserData.CountryCode = countryDialCode;
      const { value } = await props?.addUser(addUserData);
      if (value) {
        navigate("/app-users");
        openNotificationWithIcon("Success", "Added User successfully");
      }
    }
  };

  useEffect(() => {
    if (!isCalled) {
      if (params?.id) {
        callApi(params?.id);
      }
    }
  }, [isCalled]);

  const addNewEmailPhone = () => {
    setEmailPhoneNumberList([
      ...emailPhoneNumberList,
      {
        Email: "",
        CountryCode: "+1",
        PhoneNumber: "",
      },
    ]);
  };

  const removeNewEmailPhone = (index) => {
    emailPhoneNumberList.splice(index, 1);
    setEmailPhoneNumberList([...emailPhoneNumberList]);
  };

  const closeModal = () => {
    setModelOpen(false);
  };

  const deleteAppUser = async (id) => {
    const { value } = await props.appUserDelete({ Id: id });
    if (value) {
      navigate("/app-users");
      openNotificationWithIcon("Success", "User deleted successfully");
    }
  };
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "LionTree",
      description: message,
    });
  };

  const setPhoneNumber = (val, j) => {
    emailPhoneNumberList[j]["PhoneNumber"] = val;
    setEmailPhoneNumberList([...emailPhoneNumberList]);
  };
  const setCountryCode = (c, j) => {
    emailPhoneNumberList[j]["CountryCode"] = c;
    setEmailPhoneNumberList([...emailPhoneNumberList]);
  };
  const setEmail = (e, j) => {
    emailPhoneNumberList[j]["Email"] = e;
    setEmailPhoneNumberList([...emailPhoneNumberList]);
  };
  return (
    <>
      <main className="main" role="tabs">
        {userId > 0 ? (
          <>
            <div className="main__header">
              <header className="head">
                <div className="head__container">
                  <div className="head__row">
                    <div className="head__left">
                      <div className="head__close">
                        <button
                          onClick={() => navigate("/app-users")}
                          className="btn btn__close"
                        >
                          <Close />
                        </button>
                      </div>
                      <div className="head__divider"></div>
                      <div className="head__title">User Information</div>
                    </div>
                  </div>
                </div>
              </header>
            </div>
            <div className="custom__container">
              <div className="custom__wrap">
                {/* <div className="form-group avatar-upload-column">
              <label className="label-primary">Photo</label>
              <div className="user-avatar-block">
                <img
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
                  className="avatar-img"
                />
                <button className="avatar-upload-btn">
                  <Camera />
                </button>
              </div>
            </div>*/}
                <div className="form-group avatar-upload-column">
                  <label className="label-primary">Photo</label>
                  <div className="user-avatar-block">
                    <span className="text-block">
                      {userData?.AppUsers?.FirstName?.charAt(0)}
                      {userData?.AppUsers?.LastName?.charAt(0)}
                    </span>
                    <button className="avatar-upload-btn">
                      <Camera />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-primary">
                        First Name<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        name="FirstName"
                        value={userData?.AppUsers?.FirstName}
                        className="form-control"
                        required
                      />
                      {userData?.AppUsers?.FirstName === "" && (
                        <div className="invalid-feedback">
                          Please Enter FirstName
                        </div>
                      )}{" "}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-primary">
                        Last Name<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        name="LastName"
                        value={userData?.AppUsers?.LastName}
                        className="form-control"
                        required
                      />
                      {userData?.AppUsers?.LastName === "" && (
                        <div className="invalid-feedback">
                          Please Enter LastName
                        </div>
                      )}{" "}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="label-primary">
                    Roles<span className="required">*</span>
                  </label>
                  <select className="form-control">
                    <option
                      value="Seeker"
                      selected={
                        userData?.AppUserPermissions?.Tier?.toLowerCase() ===
                        "seeker"
                      }
                    >
                      Seeker{" "}
                    </option>
                    <option
                      value="Tester"
                      selected={
                        userData?.AppUserPermissions?.Tier?.toLowerCase() ===
                        "tester"
                      }
                    >
                      Tester
                    </option>
                    <option
                      value="Builder"
                      selected={
                        userData?.AppUserPermissions?.Tier?.toLowerCase() ===
                        "builder"
                      }
                    >
                      Builder
                    </option>
                    <option
                      value="Leader"
                      selected={
                        userData?.AppUserPermissions?.Tier?.toLowerCase() ===
                        "leader"
                      }
                    >
                      Leader
                    </option>
                    <option
                      value="LT"
                      selected={
                        userData?.AppUserPermissions?.Tier?.toLowerCase() ===
                        "lt"
                      }
                    >
                      LT
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="label-primary">Title (optional)</label>
                  <input
                    name="Title"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    value={userData?.AppUsers?.Title}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label className="label-primary">Headline (optional)</label>
                  <input
                    name="Headline"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    value={userData?.AppUsers?.Headline}
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label className="label-primary">Bio (optional)</label>
                  <ReactQuill
                    name="Bio"
                    // ref={richText}
                    value={userData?.AppUsers?.Bio}
                    theme="snow"
                    onChange={(content, delta, source, editor) => {
                      handleChange("Bio", editor.getHTML());
                    }}
                    //onKeyPress={(event)=> console.log(richText.current.unprivilegedEditor.getLength())}
                  />
                </div>
                {emailPhoneNumberList.length > 0 &&
                  emailPhoneNumberList.map((e, j) => {
                    return (
                      <div key={j}>
                        <div className="form-group">
                          <div className="inline-remove-field">
                            <label className="label-primary">
                              Email Address<span className="required">*</span>
                            </label>
                            {j > 0 && (
                              <button
                                onClick={() => removeNewEmailPhone(j)}
                                className="btn btn__delete--icon"
                              >
                                <Delete />
                              </button>
                            )}
                          </div>
                          <input
                            type="text"
                            value={e?.Email}
                            name="Email"
                            onChange={(e) => setEmail(e.target.value, j)}
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="label-primary">
                            Phone Number (optional)
                          </label>
                          <PhoneInput
                            // ref={phone_input}
                            inputProps={{
                              readOnly: false,
                              name: "phone",
                              required: true,
                              autoFocus: true,
                            }}
                            className="international-phone"
                            defaultCountry="US"
                            country={"US"}
                            value={e?.phoneNumber}
                            onChange={(val) => {
                              setPhoneNumber(val, j);
                              // setPhoneNumberUnknownError("");
                            }}
                            onCountryChange={(cc) => setCountryCode(cc, j)}
                            limitMaxLength="10"
                          />
                        </div>
                        {emailPhoneNumberList.length - 1 - j > 0 && <hr />}
                      </div>
                    );
                  })}
                <div className="form-group">
                  <button
                    onClick={() => addNewEmailPhone()}
                    className="btn btn__purple"
                  >
                    <Plus className="btn__icon" /> Add Email & Phone Number{" "}
                  </button>
                </div>

                <div className="stack-button-container">
                  <button className="btn btn-lg btn__purple w-100">Save</button>
                  <button
                    onClick={() => setModelOpen(!isModalOpen)}
                    className="btn btn__orange"
                  >
                    Delete User
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="main__header">
              <header className="head">
                <div className="head__container">
                  <div className="head__row">
                    <div className="head__left">
                      <div className="head__close">
                        <button
                          onClick={() => navigate("/app-users")}
                          className="btn btn__close"
                        >
                          <Close />
                        </button>
                      </div>
                      <div className="head__divider"></div>
                      <div className="head__title">User Information</div>
                    </div>
                  </div>
                </div>
              </header>
            </div>
            <div className="custom__container">
              <div className="custom__wrap">
                {/* <div className="form-group avatar-upload-column">
              <label className="label-primary">Photo</label>
              <div className="user-avatar-block">
                <img
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
                  className="avatar-img"
                />
                <button className="avatar-upload-btn">
                  <Camera />
                </button>
              </div>
            </div>*/}
                <div className="form-group avatar-upload-column">
                  <label className="label-primary">Photo</label>
                  <div className="user-avatar-block">
                    <span className="text-block">
                      {addUserData?.FirstName?.charAt(0)}
                      {addUserData?.LastName?.charAt(0)}
                    </span>
                    <button className="avatar-upload-btn">
                      <Camera />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-primary">
                        First Name<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          handleAddUserChange(e.target.name, e.target.value)
                        }
                        name="FirstName"
                        value={addUserData.FirstName}
                        className="form-control"
                        required
                      />
                      {addUserData?.FirstName === "" && (
                        <div className="invalid-feedback">
                          Please Enter FirstName
                        </div>
                      )}{" "}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-primary">
                        Last Name<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          handleAddUserChange(e.target.name, e.target.value)
                        }
                        name="LastName"
                        value={addUserData?.LastName}
                        className="form-control"
                        required
                      />
                      {addUserData?.LastName === "" && (
                        <div className="invalid-feedback">
                          Please Enter LastName
                        </div>
                      )}{" "}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="label-primary">
                    Roles<span className="required">*</span>
                  </label>
                  <select
                    onChange={(e) =>
                      handleAddUserChange("Role", e.target.value)
                    }
                    className="form-control"
                  >
                    <option
                      value="Seeker"
                      selected={addUserData?.Role?.toLowerCase() === "seeker"}
                    >
                      Seeker{" "}
                    </option>
                    <option
                      value="Tester"
                      selected={addUserData?.Role?.toLowerCase() === "tester"}
                    >
                      Tester
                    </option>
                    <option
                      value="Builder"
                      selected={addUserData?.Role?.toLowerCase() === "builder"}
                    >
                      Builder
                    </option>
                    <option
                      value="Leader"
                      selected={addUserData?.Role?.toLowerCase() === "leader"}
                    >
                      Leader
                    </option>
                    <option
                      value="LT"
                      selected={addUserData?.Role?.toLowerCase() === "lt"}
                    >
                      LT
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="label-primary">Title (optional)</label>
                  <input
                    name="Title"
                    onChange={(e) =>
                      handleAddUserChange(e.target.name, e.target.value)
                    }
                    value={addUserData?.Title}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label className="label-primary">Headline (optional)</label>
                  <input
                    name="Headline"
                    onChange={(e) =>
                      handleAddUserChange(e.target.name, e.target.value)
                    }
                    value={addUserData?.Headline}
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label className="label-primary">Bio (optional)</label>
                  <ReactQuill
                    name="Bio"
                    // ref={richText}
                    value={addUserData?.Bio}
                    theme="snow"
                    onChange={(content, delta, source, editor) => {
                      handleAddUserChange("Bio", editor.getHTML());
                    }}
                    //onKeyPress={(event)=> console.log(richText.current.unprivilegedEditor.getLength())}
                  />
                </div>
                <div>
                  <div className="form-group">
                    <div className="inline-remove-field">
                      <label className="label-primary">
                        Email Address<span className="required">*</span>
                      </label>
                    </div>
                    <input
                      type="text"
                      value={addUserData?.Email}
                      name="Email"
                      onChange={(e) =>
                        handleAddUserChange(e.target.name, e.target.value)
                      }
                      className="form-control"
                      required
                    />
                    {addUserData?.Email === "" && (
                      <div className="invalid-feedback">Please Enter Email</div>
                    )}{" "}
                    {emailError !== "" && (
                      <div className="invalid-feedback">{emailError}</div>
                    )}{" "}
                  </div>
                  <div className="form-group">
                    <label className="label-primary">
                      Phone Number (optional)
                    </label>
                    <PhoneInput
                      // ref={phone_input}
                      inputProps={{
                        readOnly: false,
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                      className="international-phone"
                      defaultCountry="US"
                      country={"US"}
                      value={addUserData?.PhoneNumber}
                      onChange={(val) => {
                        handleAddUserChange("PhoneNumber", val);
                        // setPhoneNumberUnknownError("");
                      }}
                      onCountryChange={(cc) =>
                        handleAddUserChange("CountryCode", cc)
                      }
                      limitMaxLength="10"
                    />
                  </div>
                </div>
                {emailPhoneNumberList.length > 0 &&
                  emailPhoneNumberList.map((e, j) => {
                    return (
                      <div key={j}>
                        <hr />
                        <div className="form-group">
                          <div className="inline-remove-field">
                            <label className="label-primary">
                              Email Address<span className="required"></span>
                            </label>
                            <button
                              onClick={() => removeNewEmailPhone(j)}
                              className="btn btn__delete--icon"
                            >
                              <Delete />
                            </button>
                          </div>
                          <input
                            type="text"
                            value={e?.Email}
                            name="Email"
                            onChange={(e) => setEmail(e.target.value, j)}
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="label-primary">
                            Phone Number (optional)
                          </label>
                          <PhoneInput
                            // ref={phone_input}
                            inputProps={{
                              readOnly: false,
                              name: "phone",
                              required: true,
                              autoFocus: true,
                            }}
                            className="international-phone"
                            defaultCountry="US"
                            country={"US"}
                            value={e?.phoneNumber}
                            onChange={(val) => {
                              setPhoneNumber(val, j);
                              // setPhoneNumberUnknownError("");
                            }}
                            onCountryChange={(cc) =>
                              setCountryCode(`+${getCountryCallingCode(cc)}`, j)
                            }
                            limitMaxLength="10"
                          />
                        </div>
                        {emailPhoneNumberList.length - 1 - j > 0 && <hr />}
                      </div>
                    );
                  })}
                <div className="form-group">
                  <button
                    onClick={() => addNewEmailPhone()}
                    className="btn btn__purple"
                  >
                    <Plus className="btn__icon" /> Add Email & Phone Number{" "}
                  </button>
                </div>
                <div className="stack-button-container">
                  <button
                    onClick={() => {
                      addUser();
                    }}
                    className="btn btn-lg btn__purple w-100"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </main>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        contentLabel="Are you sure?"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title react-modal-title-md">Delete</h5>
            <p className="react-modal-subtitle">
              Are you sure you want to delete this? This cannot be undone.
            </p>
            <button
              type="button"
              className="btn react-modal-close"
              onClick={() => closeModal()}
            >
              <Close />
            </button>
          </div>
          <div className="react-modal-body">
            <div className="text-right">
              <button
                onClick={() => deleteAppUser(userData?.Id)}
                className="btn btn-danger btn-lg w-150"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
