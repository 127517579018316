import { connect } from "react-redux";
import { getMe, getEvents, getAppUsers } from "../../store/home/duck";
import AdminComponent from "./component";

const AdminComponentContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    getAppUsers,
    getEvents,
    getMe,
  }
)(AdminComponent);

export default AdminComponentContainer;
