import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Close } from "../../components/svgIcons";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { Spin, notification } from "antd";
import { lt, set, sortBy } from "lodash";
import ReactModal from "react-modal";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CKEditor from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;
const jwtToken = localStorage.getItem("Authorization");

export default function AdminComponent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;
  const [date, setDate] = useState(false);
  const [agoloNewsData, setAgoloNewsData] = useState([]);
  const [isCalled, setIsCallApi] = useState(false);
  const [articleUrl, setArticleUrl] = useState("");
  const [clicked, setClicked] = useState("");
  const [articleUrlError, setArticleUrlError] = useState("");
  const [loader, setLoader] = useState(true);
  const [curatedList, setCuratedList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [introductionError, setIntroductionError] = useState("");
  let { ltWeeklyDate } = useParams();
  const [deleteNewsModal, setDeleteNewsModal] = useState(false);
  const [deletedAgoloNews, setDeletedAgoloNews] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isLtWeeklyData, setIsLtWeeklyData] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [fetchedNews, setFetchedNews] = useState([]);
  const [tagsList, setTagList] = useState([]);
  const animatedComponents = makeAnimated();
  const [searchTags, setSearchTags] = useState([]);
  const [draftNewsDates,setDraftNewsDates] = useState([]);
  const [selectedDate,setSelectedDate] = useState("All");

  const handleArticleUrl = (url) => {
    setArticleUrlError("");
    setArticleUrl(url);
  };

  const handleClick = () => {
    clicked ? setClicked("") : setClicked("data-table-show");
  };

  const closeNewsModal = () => {
    setDeletedAgoloNews("");
    setDeleteNewsModal(false);
  };

  const callCuratedTagListAPI = async () => {
    const { value } = await props.getCuratedTags();
    if (value) {
      let list = [];
      let prm = value?.Result?.map((r) => {
        let v = {
          value: r.Id,
          label: r.Name,
          color: "#00B8D9",
          isFixed: false,
        };
        list.push(v);
      });
      await Promise.all(prm);
      setCuratedList([...list]);
    }
  };

  useEffect(() => {
    if (!isCalled) {
      setIsCallApi(true);
      getLtWeeklyThemeCategoriesApi();
      setDate(ltWeeklyDate);
      callCuratedTagListAPI();
      callApi(ltWeeklyDate);
      setIsCallApi(true);
      getModifyCuratedList();
    }
  }, [
    agoloNewsData,
    fetchedNews,
    curatedList,
    tags,
    agoloNewsData,
    curatedList,
  ]);

  useEffect(() => {}, [fetchedNews]);
  const custom_config = {
    toolbar: {
      htmlEmbed: true,
      shouldNotGroupWhenFull: true,
      mediaEmbed: false,
      table: false,
    },
    removePlugins: [
      "Alignment",
      "Autoformat",
      "BlockQuote",
      "CKBox",
      "CloudServices",
      // "Essentials",
      "FontBackgroundColor",
      "FontColor",
      "FontFamily",
      "FontSize",
      "Heading",
      "Image",
      "ImageCaption",
      "ImageInsert",
      "ImageResize",
      "ImageStyle",
      "ImageToolbar",
      "ImageUpload",
      "Indent",
      "Italic",
      "Link",
      "List",
      "MediaEmbed",
      //   "Paragraph",
      //"PasteFromOffice",
      "PictureEditing",
      "SourceEditing",
      "Strikethrough",
      "Table",
      "TableCaption",
      "TableCellProperties",
      "TableColumnResize",
      //  "TableProperties",
      // "TableToolbar",
      // "TextTransformation",
      "Underline",
    ],
  };

  const handlePrimaryTag = async (i, val) => {
    let singleAgoloData = agoloNewsData[i];
    if (singleAgoloData.SecondaryCategory?.includes(val.toString())) {
      singleAgoloData.SecondaryCategory =
        singleAgoloData.SecondaryCategory.replaceAll(
          `|${val.toString()}`,
          ""
        ).replaceAll(`${val.toString()}`, "");
    }
    singleAgoloData.Category = val?.toString();
    agoloNewsData[i] = singleAgoloData;
    setAgoloNewsData([...agoloNewsData]);
    await props.updateArticles([agoloNewsData[i]]);
  };

  const handleSecondaryTag = async (i, val) => {
    let singleAgoloData = agoloNewsData[i];
    if (singleAgoloData.Category?.includes(val.toString())) {
      singleAgoloData.Category = singleAgoloData.Category.replaceAll(
        `${val.toString()}`,
        ""
      );
    }
    if (singleAgoloData.SecondaryCategory?.includes(val.toString())) {
      singleAgoloData.SecondaryCategory =
        singleAgoloData.SecondaryCategory.replaceAll(
          `|${val.toString()}`,
          ""
        ).replaceAll(`${val.toString()}`, "");
    } else {
      if (singleAgoloData.SecondaryCategory) {
        singleAgoloData.SecondaryCategory =
          singleAgoloData.SecondaryCategory + "|" + val.toString();
      } else {
        singleAgoloData.SecondaryCategory = val.toString();
      }
    }
    agoloNewsData[i] = singleAgoloData;
    setAgoloNewsData([...agoloNewsData]);
    await props.updateArticles([agoloNewsData[i]]);
  };

  const handleChange = async (i, val, key) => {
    let singleAgoloData = agoloNewsData[i];
    singleAgoloData[key] = val?.toString();
    agoloNewsData[i] = singleAgoloData;
    setAgoloNewsData([...agoloNewsData]);
  };

  const getModifyCuratedList = (value) => {
    const val = value?.split("|");
    let valList = [];
    let prm = val?.map((e, index) => {
      let isChecked = curatedList.filter((obj) => {
        return obj.value?.toString() === e;
      });
      if (isChecked.length > 0) {
        valList.push(isChecked[0]);
      }
    });
    if (val?.length > 0) Promise.all(prm);
    valList = sortBy(valList, "label");
    return valList;
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Liontree",
      description: message,
    });
  };

  const handleChangeData = (data, index, key) => {
    return (event, editor) => {
      // if (isLtWeeklyData) {
        //setIntroductionError("");
        agoloNewsData[index].Description = editor.getData();
        setAgoloNewsData([...agoloNewsData]);
      }
    // };
  };

  const handleDate = (date) => {
    const str = "Week ending ";
    date = moment(date).format("ll");
    date = str.concat(date);
    return date;
  };

  const removeOtherNews = async () => {
    setIsDeleteLoading(true);
    let payload = {
      articleId: deletedAgoloNews?.Id,
    };
    const { value } = await props.removeAgoloNews({ Id: payload?.articleId });
    if (value?.Success) {
      agoloNewsData.splice(
        agoloNewsData.findIndex((data) => data.Id === deletedAgoloNews.Id),
        1
      );
      setAgoloNewsData([...agoloNewsData]);
      closeNewsModal();
      setDeletedAgoloNews({});
      setIsDeleteLoading(false);
      fetchDraftNewsDate()
    } else {
      closeNewsModal();
      setDeletedAgoloNews({});
      setIsDeleteLoading(false);
    }
  };

  const StatusOptions = [
    { value: "all", label: "All" },
    { value: "Pending", label: "Draft" },
    { value: "Saved", label: "Saved" },
    { value: "Completed", label: "Approved" },
  ];

  const getLtWeeklyThemeCategoriesApi = async () => {
    const { value } = await props.getLtWeeklyThemeCategories();
    if (value?.Result?.length > 0) {
      value.Result = sortBy(value?.Result, (e) => e.Name?.toLowerCase());
      setTags(value?.Result);
      let list = [];
      let prm = value?.Result?.map((r) => {
        let v = {
          value: r.Title,
          label: r.Title,
          color: "#00B8D9",
          isFixed: false,
        };
        list.push(v);
      });
      await Promise.all(prm);
      setTagList([...list]);
      setLoader(false);
    }
  };

  const addCuratedList = async (e, data) => {
    let curated = [];
    let l = e?.map((e) => {
      curated.push(e.value?.toString());
    });
    await Promise.all(l);
    curated = curated.join("|");
    let payload = {
      Data: [
        {
          Id: data.Id,
          Curated: curated,
        },
      ],
    };
    const { value } = await props.addCuratedTags(payload);
    if (value?.Success) {
      const state = location?.state;
      callApi(ltWeeklyDate);
    }
  };

  const getModifiedSecondaryTag = (data, categories) => {
    let valuList = [];
    let prm = categories?.map((cat) => {
      if (data?.Category?.toString() != cat?.value?.toString()) {
        valuList.push({
          color: "#00B8D9",
          isFixed: data?.SecondaryTag?.includes(cat?.value) ? true : false,
          label: cat?.label,
          value: cat?.value,
        });
      }
    });
    return valuList;
  };

  const callApi = async (d) => {
    //d = d?.replace("Week ending ", "");
    setLoader(true)
    let endDate = new Date(d);

    let startDate = new Date(d);
    var lastSaturday = new Date(
      new Date(d).setDate(
        new Date(d).getDate() -
          (new Date(d).getDay() == 0 ? 7 : new Date(d).getDay() + 1)
      )
    );
    endDate = moment(endDate, "MM DD YYYY").format("YYYY-MM-DD");
    // startDate = startDate.setDate(startDate.getDate() - 6);
    startDate = moment(lastSaturday, "MM DD YYYY").format("YYYY-MM-DD");
    let lastDate = new Date(lastSaturday.setDate(lastSaturday.getDate() + 6));
    endDate = moment(lastDate, "MM DD YYYY").format("YYYY-MM-DD");
    let thisWeekSaturdayDate = endDate;
    let currentClickDate = moment(new Date(d), "MM DD YYYY").format(
      "YYYY-MM-DD"
    );
    let data = {
      startDate: startDate + " 00:00:00",
      endDate: endDate + " 23:59:00",
      // isThisWeek: currentClickDate === thisWeekSaturdayDate ? true : false,
      isThisWeek: true,
      status: "Pending",
    };
    const { value } = await props.getAgoloNewsByDate(data);
    if (value) {
      let news = value?.Data.reverse();
      setAgoloNewsData([...news]);
      let l = setInterval(() => {
        setLoader(false);
        setIsLtWeeklyData(true);
        clearInterval(l);
      }, 1000);
    }
  };

  useEffect(() => {}, [agoloNewsData, selectedData]);


 const handleSelectedDate=  (selectedOption)=>{ 
  console.log('Selected date:', selectedOption);
  setSelectedDate(selectedOption.value);
   getDraftNewsBySelectedDate(selectedOption.value);
 } 


 const getDraftNewsBySelectedDate = async (selectedDate) => {
let d=ltWeeklyDate;
if(selectedDate === 'All'){
  setLoader(true);
  callApi(d);
}else{
  setLoader(true);
  const formattedStartDate = moment(selectedDate).format('YYYY-MM-DD 00:00:00');
  const formattedEndDate = moment(selectedDate).format('YYYY-MM-DD 23:59:59');
const data = {
  startDate: formattedStartDate,
  endDate: formattedEndDate,
  isThisWeek: true,
  status: 'Pending',
};
const { value } = await props.getAgoloNewsByDate(data);
console.log(value, "value from Api===>")
if (value) {
  let news = value?.Data.reverse();
  console.log(news, "news===>")
  setAgoloNewsData([...news]);
  setLoader(false);
  let l = setInterval(() => {
    setIsLtWeeklyData(true);
    setLoader(false);
    clearInterval(l);
  }, 1000);
}
}
 }
 const fetchDraftNewsDate = async () => {
  let date =ltWeeklyDate;
  console.log(date, "ltWeeklyDate===>");
  const endDate = moment(date, 'MMMM-DD-YYYY').format('YYYY-MM-DD');
  const startDate = moment(endDate, 'YYYY-MM-DD').subtract(6, 'days').format('YYYY-MM-DD');
  // const startDate = moment(date, 'MMMM-DD-YYYY').format('YYYY-MM-DD');
  const formattedStartDate = startDate + ' 00:00:00';
  const formattedEndDate = endDate + ' 23:59:59';
  
  const value = {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  };
  console.log(value,"date values===>")
  const dates = await props.getAllCreatedDraftAgoLoNewsDate(value);
  console.log('Group dates', dates);

  const formattedDates = dates?.value?.Result?.map(item => ({
    value: item.GroupDate,
    label: moment(item.GroupDate).format('MMM DD, YYYY'), // Format the date as you need
  })) || [];
  setDraftNewsDates(formattedDates);
 }


 useEffect  (() => {
  fetchDraftNewsDate();
  }, []);


  const fetchArticle = async () => {
    if (articleUrl.trim()) {
      // setFetchedNews([])
      setArticleUrlError("");
      setIsLoading(true);
      const { value } = await props.getArticleFromUrl({
        url: articleUrl,
        weeklyDate: ltWeeklyDate,
      });
      if (value?.title) {
        setLoader(true);
        callApi(ltWeeklyDate);
        setArticleUrl("");
        // setFetchedNews([...fetchedNews, data])
        fetchDraftNewsDate()
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setArticleUrlError("Not found data please enter another url");
      }
    } else {
      setArticleUrlError("Please enter url");
    }
  };

  const handleSave = async (an, i) => {
    // let temp = agoloNewsData;
    if (an?.Category !== "") {
      setSelectedData([...selectedData, an.Id]);
      an.Status = "Saved";
      const { value } = await props.updateArticles([an]);
      if (value?.Success) {
        // setLoader(true)
        // callApi(ltWeeklyDate)
        agoloNewsData.splice(
          agoloNewsData.findIndex((data) => data.Id === an.Id),
          1
        );
        setAgoloNewsData([...agoloNewsData]);
        let keyData = selectedData.filter((data, j) => data != an.Id);
        setSelectedData([...keyData]);
      }
    } else {
      openNotificationWithIcon("error", "Please select Primary Category");
    }
  };

  const getSearchTags = (an, i) => {
    if (searchTags[i] === "") {
      return tags
        ?.sort((a, b) => a.Title.localeCompare(b.Title))
        .map((t, index) => {
        return (
          <li>
            <span>{t?.Title}</span>
            <div className="btn-container-item">
              {/* {an.Category > 0 } */}
              <button
                name="primary"
                value={t?.Title}
                className={
                  t?.Title == an.Category
                    ? "btn btn__purple"
                    : "btn btn__default"
                }
                onClick={(e) => handlePrimaryTag(i, e.target.value)}
              >
                Primary
              </button>
              <button
                name="secondary"
                value={t?.Title}
                className={
                  an.SecondaryCategory?.includes(t?.Title)
                    ? "btn btn__purple"
                    : "btn btn__default"
                }
                onClick={(e) => {
                  handleSecondaryTag(i, e.target.value);
                }}
              >
                Secondary
              </button>
            </div>
          </li>
        );
      });
    } else if (searchTags[i] !== "") {
      let search = "piz";
      let condition = new RegExp(searchTags[i], "i");
      let result = tags.filter(function (el) {
        return condition.test(el.Name) || condition.test(el.Title);
      });
      return result
        ?.sort((a, b) => a.Title.localeCompare(b.Title))
        .map((t, index) => {
        return (
          <li>
            <span>{t?.Title}</span>
            <div className="btn-container-item">
              {/* {an.Category > 0 } */}
              <button
                name="primary"
                value={t?.Title}
                className={
                  t?.Title == an.Category
                    ? "btn btn__purple"
                    : "btn btn__default"
                }
                onClick={(e) => handlePrimaryTag(i, e.target.value)}
              >
                Primary
              </button>
              <button
                name="secondary"
                value={t?.Title}
                className={
                  an.SecondaryCategory?.includes(t?.Title)
                    ? "btn btn__purple"
                    : "btn btn__default"
                }
                onClick={(e) => {
                  handleSecondaryTag(i, e.target.value);
                }}
              >
                Secondary
              </button>
            </div>
          </li>
        );
      });
    }
  };
  const emailSendLtTeam = async() => {
    let isTrue = true;
    let prm = agoloNewsData.map(an=>{
      if(an.Category === null || an.Category === "" || an.Category === undefined ){
        openNotificationWithIcon('error',"We are unable to send email due to primary category are missing in some of the news.")
        isTrue = false;
        return;
      }
    })
    if(isTrue){
      const { value } = await props.SendEmailToLtTeam({date: ltWeeklyDate})
      if(value?.Success){
        openNotificationWithIcon("success","Email sent to LT team successfully.")
      }
    }
  }

  const searchTagText = (eve, tags, index) => {
    searchTags[index] = eve.target.value;
    setSearchTags([...searchTags]);
  };
  // Slect End
  return (
    <>
      <main
        className="main viewother__news-wrapper"
        style={{ paddingBottom: "90px" }}
        role="head"
      >
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate(`/other-news/${ltWeeklyDate}`)}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">Add News Article</div>
                </div>
                {/*<button
                className="btn btn__purple"
                disabled={agoloNewsData?.length === 0 ? true : false}
                onClick={() => emailSendLtTeam()}
              >
                Email LT Team
            </button>*/}
              </div>
            <div>
           </div>

            </div>
          </header>
        </div>
        <>
          {loader && (
            <Spin
              size="large"
              indicator={
                <LoadingOutlined style={{ fontSize: 30, color: "#000" }} spin />
              }
              className="loader__full"
            />
          )}
          <br />
          <div className="custom__container" style={{ maxWidth: 752 }}>
            <div className="d-flex flex-column gap-3 mb-3"></div>
            <div className="form-group">
              <label className="label-primary">Article URL</label>
              <div className="editor-instance-new">
                <input
                  type="text"
                  name="ExternalUrl"
                  className="form-control"
                  onChange={(e) => handleArticleUrl(e.target.value)}
                  value={articleUrl}
                  required
                />
              </div>
              {articleUrlError !== "" && (
                <div className="invalid-feedback">{articleUrlError}</div>
              )}
            </div>
            <div className="form-group">
              <label className="label-primary">
                IIM Weekly being added to:
              </label>
              <div className="">{handleDate(date)}</div>
            </div>
            <button
              type="submit"
              class="btn btn-lg btn__purple w-100"
              onClick={() => fetchArticle()}
            >
              {isLoading ? <Spin indicator={antIcon} /> : "Add Article"}
            </button>
          
          <div className="d-flex align-items-center mt-4">
            <label className='me-2 custom-label'>
              Date Added
            </label>
            <Select
                      defaultValue={{ value: 'All', label: 'All' }}
                      className='react-select-container min-width-select'
                      placeholder='All News'
                      // isClearable={false}
                      name='newsArticle'
                      // closeMenuOnSelect={false}
                      options={[{ value: 'All', label: 'All' }, ...draftNewsDates]}
                      onChange={handleSelectedDate}
                    />
          </div>
          </div>
          <br />
          {agoloNewsData?.length > 0 && (
            <div className="data-table-show">
              <div className="custom__container x-hidden">
                {/*                <div className="table-responsive table__responsive--custom overlayflow-y-auto">*/}
                <div className="table__responsive--custom">
                  <table className="table table__custom">
                    <thead style={{ borderTop: '1px solid #E3E8EE' }}>
                      <tr>
                        {/* <th className="table__selection--column">Description</th> */}
                        <th className="table__selection--column">Article</th>
                        {/* <th>Source</th> */}
                        <th>Tags</th>
                        <th></th>
                        <th
                          style={{
                            width: "400px",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                          }}
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loader ? (
                        <Spin
                          size="large"
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 30, color: "#000" }}
                              spin
                            />
                          }
                          className="loader__full"
                        />
                      ) : (
                        agoloNewsData?.length > 0 &&
                        agoloNewsData?.map((an, i) => {
                          return (
                            <tr key={i}>
                              <td className="table-description">
                                <th className="">Title</th>
                                {/* <textarea
                                  name="title"
                                  rows="3"
                                  cols="55"
                                  onChange={(e) =>
                                    handleChange(i, e.target.value, "Title")
                                  }
                                  className="form-control"
                                  value={an.Title}
                                  required
                                /> */}
                                <textarea
                                  rows={1}
                                  name="title"
                                  onChange={(e) =>
                                    handleChange(i, e.target.value, "Title")
                                  }
                                  className="form-control"
                                  value={an.Title}
                                  required
                                />
                                <br />
                                <th className="">Description</th>
                                <tr className="table-editor-block editor-page--container">
                                  <CKEditor
                                    editor={Editor}
                                    data={an?.Description}
                                    config={custom_config}
                                    onChange={handleChangeData(
                                      an,
                                      i,
                                      "Description"
                                    )}
                                  />
                                  <div className="text-end mt-1">{an?.Description.replaceAll("<strong>","").replaceAll("</b>","").replaceAll("<b>","").replaceAll("</strong>","").replaceAll("<p>","").replaceAll("</p>","").length}</div>                                </tr>
                                <br />
                                <th className="">Source</th>
                                <textarea
                                  rows={1}
                                  type="text"
                                  name="source"
                                  onChange={(e) =>
                                    handleChange(i, e.target.value, "Source")
                                  }
                                  className="form-control"
                                  value={an.Source}
                                  required
                                />
                                <tr className="">
                                  {/* {an.Source.toUpperCase()} */}
                                </tr>
                                <br />
                                <th className="">Article Url</th>
                                <tr className="add-btn">
                                  <a
                                    className="add-btn"
                                    target={"_blank"}
                                    href={an.Url}
                                  >
                                    {an.Url}
                                  </a>
                                </tr>
                                <br />
                              </td>
                              <td>
                                <th className="">Search Tags</th>
                                <div className="tag__group">
                                  <input
                                    className="react-select-container form-control"
                                    name="searchTags"
                                    type="text"
                                    value={searchTags[i]}
                                    onChange={(e) => searchTagText(e, tags, i)}
                                  />
                                </div>
                                <br/>
                                <div className="table-list-container">
                                  <ul>
                                  {getSearchTags(an, i)}
                                  </ul>
                                </div>
                                <th className="">Curated List</th>
                                <div className="tag__group">
                                  <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    defaultValue={getModifyCuratedList(
                                      an.Curated
                                    )}
                                    isMulti
                                    onChange={(e) => addCuratedList(e, an)}
                                    options={curatedList}
                                  />
                                </div>
                                <tr></tr>
                              </td>
                              <td>
                                <th>Primary Tag</th>
                                <tr>
                                  {an.Category === null || an.Category === ""
                                    ? "-"
                                    : an.Category?.split("|")[0]}
                                </tr>
                                <br />
                                <th>Secondary Tag</th>
                                <tr>
                                  {an.SecondaryCategory === null ||
                                  an.SecondaryCategory === ""
                                    ? "-"
                                    : an.SecondaryCategory !== "" &&
                                      an.SecondaryCategory
                                    ? an.SecondaryCategory?.split("|")?.map(
                                        (sc) => {
                                          return <p>{sc}</p>;
                                        }
                                      )
                                    : an.Category?.split("|")?.map((sc, i) => {
                                        if (i > 0) {
                                          return <p>{sc}</p>;
                                        }
                                      })}
                                </tr>
                              </td>
                              <td
                                className="table__status"
                                style={{
                                  // width: "400px",
                                  paddingLeft: "30px",
                                  paddingRight: "30px",
                                }}
                              >
                                <tr>Draft</tr>
                              </td>
                              <td>
                                <button
                                  disabled={
                                    selectedData.indexOf(an.Id) > -1
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    handleSave(an, i);
                                  }}
                                  className="add-btn me-5"
                                >
                                  <span className="text-save">Save</span>
                                  {selectedData.indexOf(an.Id) > -1 && (
                                    <span className="loader__save">
                                      <Spin
                                        style={{
                                          color: "#000",
                                          fontSize: "16px",
                                        }}
                                        indicator={antIcon}
                                      />
                                    </span>
                                  )}
                                </button>
                                <button
                                  onClick={() => {
                                    setDeleteNewsModal(true);
                                    setDeletedAgoloNews(an);
                                  }}
                                  className="remove-btn"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                {/* <h2>akjsdhkjadh</h2> */}
              </div>
            </div>
          )}
        </>
        <ReactModal
          isOpen={deleteNewsModal}
          onRequestClose={closeNewsModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Delete Other News</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to delete this other news article? This
                action cannot be undone.
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={closeNewsModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => removeOtherNews()}
                className="btn btn__orange ms-2"
              >
                {isDeleteLoading ? <Spin indicator={antIcon} /> : "Yes, Delete"}
              </button>
            </div>
          </div>
        </ReactModal>
      </main>
    </>
  );
}
